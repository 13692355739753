import axios from 'axios';
import { jwtService } from 'app/auth/jwtService';

// Create an instance of axios without interceptors for token validity check
const axiosNoInterceptors = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://staging.ccticketing.eu/ticketingApi/',
});

// Create the main axios instance
const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL || 'https://staging.ccticketing.eu/ticketingApi/',
});

// Add a request interceptor
axiosApi.interceptors.request.use(
    async (config) => {
        const token = jwtService.getAccessToken();

        if (token && jwtService.isAuthTokenValid(token)) {
            config.headers.Authorization = `Bearer ${token}`;
        } else {
            jwtService.logout();
            throw new axios.Cancel("Session expired");
        }
        return config;
    },
    (error) => Promise.reject(error)
);


// Function to handle global logout on 401 error
const handle401Error = (error) => {
    jwtService.logout("Αποσυνδεθήκατε");
    return Promise.reject(error);
};

// Add a response interceptor
axiosApi.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            handle401Error(error);
        }
        return Promise.reject(error);
    }
);

export { axiosApi, axiosNoInterceptors };
