import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {fetchLatestNoteApi, fetchTickets as fetchTicketsApi} from 'app/Api Calls/TicketApi';

export const fetchTickets = createAsyncThunk(
    'tickets/fetchTickets',
    async ({ page, size }) => {
        const response = await fetchTicketsApi(page, size);
        return response;
    }
);

export const fetchLatestNote = createAsyncThunk(
    'tickets/fetchLatestNote',
    async (ticketId) => {
        const response = await fetchLatestNoteApi(ticketId);
        return { ticketId, note: response };
    }
);

const initialState = {
    tickets: [],
    loading: false,
    error: null,
    page: 1,
    totalPages: 0,
    ticketNotes: {},
    noteLoading: false
};

const ticketsSlice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTickets.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTickets.fulfilled, (state, action) => {
                state.loading = false;
                state.tickets = action.payload.elements;
                state.totalPages = action.payload.totalPages;
            })
            .addCase(fetchTickets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchLatestNote.pending, (state) => {
                state.noteLoading = true;
            })
            .addCase(fetchLatestNote.fulfilled, (state, action) => {
                state.noteLoading = false;
                state.ticketNotes[action.payload.ticketId] = action.payload.note;
            })
            .addCase(fetchLatestNote.rejected, (state, action) => {
                state.noteLoading = false;
                state.error = action.error.message;
            });
    },
});

export const { setPage } = ticketsSlice.actions;

export default ticketsSlice.reducer;
export const ticketsInitialState = initialState;
