import React, {useEffect} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SplashScreen from 'app/auth/SplashScreen';
import {useAuth} from "app/auth/AuthContext";

const Authorization = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  const isOnSignInPage = location.pathname === '/sign-in';

 useEffect(() => {
    if (!isAuthenticated && !isOnSignInPage) {
      navigate('/sign-in');
    } else if (isAuthenticated && isOnSignInPage) {
      navigate('/mypage');
    }
  }, [navigate]);

  if (isAuthenticated === undefined) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default Authorization;
