import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchCarriers,
    fetchThematicsByCarrierId,
    selectCarrier,
    fetchThematicsByParentId,
    selectThematic
} from 'app/store/knowledgeBaseSlice';
import CarrierList from 'app/main/KnowledgeBase/CarrierList';
import { useNavigate } from "react-router-dom";

function Tree() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { carriers, loading, thematicMap } = useSelector((state) => state.knowledgeBase);

    useEffect(() => {
        if (carriers.length === 0) {
            dispatch(fetchCarriers());
        }
    }, [dispatch, carriers.length]);

    const handleCarrierSelect = (carrier) => {
        if (carrier.id === "10010") {
            const predefinedCarrier = carriers.find(c => c.id === 12);
            if (predefinedCarrier) {
                dispatch(selectCarrier(predefinedCarrier));
                dispatch(selectThematic(carrier));
                dispatch(fetchThematicsByParentId(carrier.id));
            }
        } else {
            dispatch(selectCarrier(carrier));
            if (!thematicMap.carriers[carrier.id]) {
                dispatch(fetchThematicsByCarrierId(carrier.id));
            }
        }
        navigate("/thematicqnas");
    };

    return (
        <div className="App container mx-auto p-4 flex flex-col items-center mt-8">
            <CarrierList carriers={carriers} onSelect={handleCarrierSelect} loading={loading} />
        </div>
    );
}

export default Tree;
