import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchCarriers,
    fetchThematicsByCarrierId,
    fetchThematicsByParentId,
    selectCarrier,
    selectThematic,
    navigateToThematicLevel,
    clearSelections,
    fetchGeographiesByCarrierId,
    selectGeography,
    setLevelLoading
} from 'app/store/knowledgeBaseSlice';
import { Button, Box, Typography } from '@mui/material';
import CustomSelect from "app/main/SignedIn/Reusable Components/CustomSelect";

const DynamicSelector = ({ thematicError, geographyError, setThematicError, setGeographyError }) => {
    const dispatch = useDispatch();
    const {
        carriers,
        thematicMap,
        selectedCarrier,
        selectedThematic,
        selectedThematics,
        loading,
        geographies,
        selectedGeography,
        geographiesLoading,
        levelLoading = {} // Default to an empty object
    } = useSelector((state) => state.knowledgeBase);

    useEffect(() => {
        const loadCarriers = async () => {
            await dispatch(fetchCarriers());
        };

        if (carriers.length === 0) {
            loadCarriers();
        }
    }, [dispatch, carriers.length]);

    useEffect(() => {
        if (selectedCarrier?.id === 12 && selectedThematics.length === 0) {
            dispatch(selectThematic({ id: 10010, title: 'Auto Selected Thematic' }));
            dispatch(fetchThematicsByParentId(10010));
        }
    }, [selectedCarrier, dispatch, selectedThematics.length]);


    useEffect(() => {
        if( thematicMap.thematics[selectedThematic?.id]?.length === 0)
        {
            if (selectedThematics.length > 0) {
                const lastSelectedThematic = selectedThematics[selectedThematics.length - 1];
                setThematicError(computeThematicErrorCondition(lastSelectedThematic, thematicMap, selectedThematics));
            } else {
                setThematicError(true);
            }
        }
    }, [selectedThematic, thematicMap]);

    const fetchGeographies = (carrierId) => {
        if (carrierId === 2 && (!geographies[carrierId] || geographies[carrierId].length === 0)) {
            dispatch(fetchGeographiesByCarrierId(carrierId));
        }
    };

    const handleCarrierChange = (event) => {
        const carrierId = event.target.value;
        const carrier = carriers.find(carrier => carrier.id === carrierId);
        dispatch(selectCarrier(carrier));
        dispatch(fetchThematicsByCarrierId(carrierId));
        fetchGeographies(carrierId);
        setThematicError(true);
        setGeographyError(carrierId === 2);
    };

    const computeThematicErrorCondition = (selectedThematic, thematicMap, selectedThematics) => {
        return !(
            selectedThematic &&
            Array.isArray(thematicMap.thematics[selectedThematic.id]) &&
            thematicMap.thematics[selectedThematic.id].length === 0 &&
            selectedThematics &&
            selectedThematics.length > 0 &&
            thematicMap.thematics[selectedThematics[selectedThematics.length - 1].id].length === 0
        );
    };

    const handleThematicChange = async (event, level) => {
        const thematicId = event.target.value;
        const parentId = level === 0 ? selectedCarrier?.id : selectedThematics[level - 1]?.id;
        const thematicsAtLevel = level === 0
            ? (selectedCarrier?.id === 12 && selectedThematics.length === 0 ? thematicMap.thematics[10010] || [] : thematicMap.carriers[selectedCarrier?.id])
            : thematicMap.thematics[parentId];
        const selectedThematic = thematicsAtLevel?.find(thematic => thematic.id === thematicId);

        if (selectedThematic) {
            const newSelectedThematics = selectedThematics.slice(0, level);
            newSelectedThematics[level] = selectedThematic;

            // Set loading state for the next level
            const nextLevel = level + 1;
            dispatch(setLevelLoading({ level: nextLevel, loading: true }));

            // Dispatch selectThematic action and fetch thematics for the next level
            await dispatch(selectThematic(selectedThematic));
            await dispatch(fetchThematicsByParentId(thematicId));

            // Unset loading state for the next level
            dispatch(setLevelLoading({ level: nextLevel, loading: false }));

        }
    };



    const handleGeographyChange = (event) => {
        const geographyId = event.target.value;
        const carrierGeographies = Object.values(geographies[selectedCarrier.id] || {});
        const selectedGeography = carrierGeographies.find(geo => geo.id === geographyId);
        dispatch(selectGeography(selectedGeography));
        setGeographyError(selectedCarrier?.id === 2 && !selectedGeography);
    };

    const handleClearSelection = (level) => {
        if (level === 0) {
            dispatch(clearSelections());
            // Compute thematic error condition after clearing selections
            setThematicError(computeThematicErrorCondition(null, thematicMap, []));
        } else {
            dispatch(navigateToThematicLevel({ index: level - 1 }));
            // Compute thematic error condition for the new state
            const newSelectedThematics = selectedThematics.slice(0, level - 1);
            const newSelectedThematic = newSelectedThematics[newSelectedThematics.length - 1];
            setThematicError(computeThematicErrorCondition(newSelectedThematic, thematicMap, newSelectedThematics));
        }
    };


    const handleReset = () => {
        dispatch(clearSelections());
        setThematicError(true);
        setGeographyError(selectedCarrier?.id === 2);
    };

    const renderCarrierSelect = () => (
        <CustomSelect
            label="Φορέας"
            value={selectedCarrier?.id || ''}
            onChange={handleCarrierChange}
            onClear={() => handleClearSelection(0)}
            options={carriers}
            loading={loading}
        />
    );

    const renderGeographySelect = () => {
        if (!selectedCarrier || selectedCarrier.id !== 2) {
            return null;
        }
        fetchGeographies(selectedCarrier.id);

        const carrierGeographies = Object.values(geographies[selectedCarrier.id] || {})
            .map(geo => ({
                id: geo.id,
                title: geo.name
            }))
            .sort((a, b) => a.title.localeCompare(b.title));

        if (carrierGeographies.length === 0) {
            return null;
        }

        return (
            <Box>
                <CustomSelect
                    label="Γεωγραφία"
                    value={selectedGeography?.id || ''}
                    onChange={handleGeographyChange}
                    onClear={() => dispatch(selectGeography(null))}
                    options={carrierGeographies}
                    loading={geographiesLoading}
                />
                {geographyError && selectedCarrier?.id === 2 && (
                    <Typography variant="caption" color="error">
                        Παρακαλώ επιλέξτε γεωγραφία.
                    </Typography>
                )}
            </Box>
        );
    };

    const renderThematicSelect = (level) => {
        const parentId = level === 0 ? selectedCarrier?.id : selectedThematics[level - 1]?.id;
        const thematicsAtLevel = level === 0
            ? (selectedCarrier?.id === 12 && selectedThematics.length === 0 ? thematicMap.thematics[10010] || [] : thematicMap.carriers[selectedCarrier?.id])
            : thematicMap.thematics[parentId];

        if (!thematicsAtLevel || thematicsAtLevel.length === 0) {
            return null;
        }

        let label = '';
        if (level === 0) {
            label = 'Θεματική Ενότητα';
        } else if (level === 1) {
            label = 'Θέμα';
        } else if (level === 2) {
            label = 'Υποθέμα';
        }

        return (
            <CustomSelect
                key={level}
                label={label}
                value={selectedThematics[level]?.id || ''}
                onChange={(e) => handleThematicChange(e, level)}
                onClear={() => handleClearSelection(level + 1)}
                options={thematicsAtLevel}
                loading={levelLoading[level] || false}
            />
        );
    };

    return (
        <Box>
            {!loading && renderCarrierSelect()}
            {renderGeographySelect()}
            {selectedCarrier && (
                <Box>
                    <Box>
                        {selectedCarrier.id !== 12 && renderThematicSelect(0)}
                        {selectedThematics.map((_, level) => renderThematicSelect(level + 1))}
                    </Box>
                </Box>
            )}
            {thematicError && (
                <div>
                    <Typography variant="caption" color="error">
                        Παρακαλώ επιλέξτε όλο το δέντρο της θεματολογίας.
                    </Typography>
                </div>
            )}
            <Button onClick={handleReset} sx={{ mt: 1 }}>Διαγραφή</Button>
        </Box>
    );
};

export default DynamicSelector;
