import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "themes/theme";
import TicketRow from "app/main/SignedIn/MyTickets/TicketRow";

const TicketsTable = ({
                          tickets,
                          expandedRow,
                          handleRowClick,
                          thematics,
                          ticketNotes,
                          ticketFiles,
                          thematicsLoading,
                          noteLoading,
                          loadingTicketId
                      }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <TableContainer sx={{ backgroundColor: isMobile ? '#EDF0F9' : 'white', maxWidth: '100%' }} component={Paper}>
            <Table>
                {!isMobile && (
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="center"
                                sx={{
                                    fontWeight: '600',
                                    color: '#003476',
                                    fontSize: '15px',
                                    width: '24%',
                                }}
                            >
                                Αριθμός Ticket
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontWeight: '600',
                                    color: '#003476',
                                    fontSize: '15px',
                                    width: '22%',
                                }}
                            >
                                Φορέας
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontWeight: '600',
                                    color: '#003476',
                                    fontSize: '15px',
                                    width: '23%',
                                }}
                            >
                                Ημερομηνία Δημιουργίας
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontWeight: '600',
                                    color: '#003476',
                                    fontSize: '15px',
                                    width: '10%',
                                }}
                            >
                                Κατάσταση
                            </TableCell>
                            <TableCell
                                align="center"
                                sx={{
                                    fontWeight: '600',
                                    color: '#003476',
                                    fontSize: '15px',
                                    width: '15%',
                                }}
                            />
                        </TableRow>
                    </TableHead>
                )}
                <TableBody>
                    {tickets.map((ticket) => (
                        <TicketRow
                            key={ticket.id}
                            ticket={ticket}
                            expandedRow={expandedRow}
                            handleRowClick={handleRowClick}
                            thematics={thematics}
                            ticketNotes={ticketNotes}
                            ticketFiles={ticketFiles}
                            thematicsLoading={thematicsLoading}
                            noteLoading={noteLoading}
                            loadingTicketId={loadingTicketId}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TicketsTable;
