import { axiosApi } from "app/configs/axiosConfig";

// Function to fetch specific user
export async function getSpecificUser() {
    try {
        const response = await axiosApi.get(`/webportal/user/getBeneficiary`);
        return { data: response.data, status: response.status };
    } catch (error) {
        console.error('Failed to fetch user:', error);
        return { data: null, status: error.response ? error.response.status : 500 };
    }
}

// Function to update user information
export async function updateUser(user) {
    try {
        const response = await axiosApi.put(`/webportal/user/beneficiary/update`, user);
        return response;
    } catch (error) {
        console.error('Failed to update user:', error);
        return null;
    }
}

// Function to create a new user
export async function createUser(user) {
    // Define the user object with necessary fields
    const userPayload = {
        afm: user.afm || null,
        firstName: user.firstName || null,
        lastName: user.lastName || null,
        email: user.email,
        phone: user.phone || null,
        phone2: user.phone2 || null,
        mobile: user.mobile,
        ama: user.ama || null,
        amka: user.amka,
        ada: user.ada || null,
        amoga: user.amoga || null,
        adt: user.adt || null,
        address: user.address || null
    };

    try {
        const response = await axiosApi.post(`/webportal/user/beneficiary/create`, userPayload);
        return response;
    } catch (error) {
        console.error('Failed to create user:', error);
        return null;
    }
}
