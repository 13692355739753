import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pagination from '@mui/material/Pagination';
import linkifyHtml from 'linkify-html';
import 'linkify-plugin-hashtag';
import Button from "@mui/material/Button";
import { PaginationItem } from "@mui/material";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useSelector } from "react-redux";
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import { jwtService } from "app/auth/jwtService";
import { useNavigate } from "react-router-dom";

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    '& a': {
        color: '#003476',
        textDecoration: 'underline',
        overflowWrap: 'break-word',
    },
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    '& .MuiPaginationItem-root': {
        backgroundColor: 'white',
        border: '1px solid #d0d0d0',
        borderRadius: '4px',
        color: '#003476',
        height: '30px',
        '&:hover': {
            backgroundColor: '#f0f0f0',
        },
        '&.Mui-selected': {
            backgroundColor: '#003476',
            color: 'white',
            borderColor: '#003476',
        },
    },
    '& .MuiPaginationItem-ellipsis': {
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        border: '1px solid #d0d0d0',
    },
}));

const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#003476',
    color: '#ffffff',
    fontSize: '1.0rem',
    padding: '8px 12px',
    '&:hover': {
        backgroundColor: '#002f6c',
    },
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        fontSize: '0.875rem',
        padding: '6px 10px',
        marginTop: '2rem',
    },
}));

const QnaList = ({ handlePageChange }) => {
    const linkifyOptions = {
        defaultProtocol: 'http',
        target: '_blank'
    };
    const { qnas, page, totalPages, selectedCarrier } = useSelector(state => state.knowledgeBase);
    const user = jwtService.getUser();
    const token = jwtService.getAccessToken();
    const isSignedIn = user && user.id && token;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const handleButtonClick = () => {
        if (isSignedIn) {
            navigate('/ticketform');
        } else {
            window.location.href = process.env.REACT_APP_TAXIS_LOGIN;
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [page]);

    return (
        <div style={{ width: isMobile ? '100%' : "80%", minHeight: "30vh", marginTop: '4rem', margin: '0 auto' }}>
            <Box sx={{ backgroundColor: '#003476', color: 'white', p: 2 }}>
                <Typography variant="h6" className="text-center">
                    Συχνές Ερωτήσεις
                </Typography>
            </Box>
            {qnas.length > 0 ? (
                qnas.map((qna) => (
                    <Accordion key={qna.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography component="div" className="px-2">{qna.question}</Typography>
                        </AccordionSummary>
                        <StyledAccordionDetails>
                            <div
                                className="px-4"
                                dangerouslySetInnerHTML={{ __html: linkifyHtml(qna.answer, linkifyOptions) }}
                            />
                        </StyledAccordionDetails>
                    </Accordion>
                ))
            ) : selectedCarrier?.id !== 12 ? (
                <Typography variant="h6" className="text-center" sx={{ mt: 2 }}>
                    Δεν υπάρχουν ερωτοαπαντήσεις στην συγκεκριμένη Θεματική
                </Typography>
            ) : (
                <Typography variant="body1" sx={{ mt: 2, '& a': { color: '#003476', textDecoration: 'underline', fontWeight: '600' } }}>
                    Το ψηφιακό αίτημα αποστολής ηχογραφημένης κλήσης του 1555 πρέπει να συνοδεύεται απαραιτήτως από Υπεύθυνη Δήλωση, υπογεγραμμένη μέσω της ενιαίας ψηφιακής πύλης της Δημόσιας Διοίκησης <a href="https://www.gov.gr/" target="_blank" rel="noopener noreferrer">www.gov.gr</a>.<br />
                    Η Υπεύθυνη Δήλωση απευθύνεται προς το 1555 – Αρμόδιο Φορέα και φέρει το παρακάτω πρότυπο κείμενο:<br />
                    «Δηλώνω ότι με την παρούσα αιτούμαι τη λήψη της ηχογραφημένης κλήσης η οποία πραγματοποιήθηκε από τον τηλεφωνικό αριθμό ……………………… , που με είχατε ενημερώσει ότι έχετε καταγράψει στις ……….. και ζητώ να μου αποστείλετε το εν λόγω αρχείο στην ηλεκτρονική διεύθυνση ………………………».<br />
                    <strong>Επισημαίνεται πως οι κλήσεις του 1555 διατηρούνται για ένα έτος.</strong>
                </Typography>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, justifyContent: 'space-between' }}>
                <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
                    <Grid item xs={12} sm={qnas.length > 0 ? 8 : 12}>
                        {qnas.length > 0 && (
                            <StyledPagination
                                count={totalPages}
                                page={page}
                                color="primary"
                                onChange={handlePageChange}
                                renderItem={(item) => (
                                    <PaginationItem
                                        {...item}
                                        components={{ previous: 'span', next: 'span' }}
                                        slots={{
                                            previous: () => (
                                                <span>Πίσω</span>
                                            ),
                                            next: () => (
                                                <span>Εμπρός</span>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={qnas.length > 0 ? 4 : 12} sx={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-end' }}>
                        <StyledButton
                            variant="contained"
                            onClick={handleButtonClick}
                        >
                            Υποβολη Αιτηματος
                            <ArrowCircleRightIcon sx={{ ml: 1 }} />
                        </StyledButton>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default QnaList;
