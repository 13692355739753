import React from 'react';
import { Grid, Typography, TextField as MuiTextField } from '@mui/material';
import { CustomTextField } from 'app/main/SignedIn/TicketForm/utils';

const BasicInfo = ({ firstName, lastName, afm, amka, setAmka, errors, setErrors }) => {
    const handleAmkaChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setAmka(value);
            if (!value || value.length !== 11) {
                setErrors((prev) => ({ ...prev, amka: 'Παρακαλώ εισάγετε ένα έγκυρο ΑΜΚΑ (11 ψηφία)' }));
            } else {
                setErrors((prev) => ({ ...prev, amka: '' }));
            }
        }
    };

    return (
        <Grid item xs={12} sm={6}>
            <Typography variant="h5">
                Βασικά Στοιχεία
            </Typography>
            <CustomTextField
                fullWidth
                label="ΕΠΩΝΥΜΟ"
                variant="outlined"
                value={lastName || ""}
                InputProps={{
                    readOnly: true,
                }}
                sx={{ input: { cursor: 'not-allowed' } }}
            />
            <CustomTextField
                fullWidth
                label="ΟΝΟΜΑ"
                variant="outlined"
                value={firstName || ""}
                InputProps={{
                    readOnly: true,
                }}
                sx={{ input: { cursor: 'not-allowed' } }}
            />
            <CustomTextField
                fullWidth
                label="Α.Φ.Μ"
                variant="outlined"
                value={afm}
                InputProps={{
                    readOnly: true,
                }}
                sx={{ input: { cursor: 'not-allowed' } }}
            />
            <CustomTextField
                fullWidth
                label="(*) ΑΜΚΑ"
                variant="outlined"
                value={amka || ""}
                onChange={handleAmkaChange}
                error={!!errors.amka}
                helperText={errors.amka}
            />
        </Grid>
    );
};

export default BasicInfo;
