import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import { useTheme, useMediaQuery } from '@mui/material';
import CustomTypography from "app/main/SignedIn/Reusable Components/CustomTypography";
import { jwtService } from 'app/auth/jwtService';
import { resetKnowledgeBaseExceptCarrier } from 'app/store/knowledgeBaseSlice';
import SignOutPage from './SignOutPage';

const APP_BAR_HEIGHT = 64;

function HeaderWrapper({ children }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [showScroll, setShowScroll] = useState(false);
    const user = jwtService.getUser();
    const token = jwtService.getAccessToken();
    const isSignedIn = user && user.id && token;
    const theme = useTheme();
    const isBelow1300 = useMediaQuery(theme.breakpoints.down(1300));
    const isActiveLink = (path) => location.pathname === path;

    const handleResetKnowledgeBase = () => {
        dispatch(resetKnowledgeBaseExceptCarrier());
        navigate('/main');
    };

    const handleLogout = () => {
        navigate('/sign-out');
        setDrawerOpen(false);
    };

    const handleLogoClick = () => {
        if (isSignedIn) {
            navigate('/mypage');
        } else {
            handleResetKnowledgeBase();
        }
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const drawerContent = (
        <Box sx={{ width: 250, backgroundColor: '#003476', height: '100%', color: 'white' }}>
            <List>
                <ListItem>
                    <IconButton color="inherit" edge="start" onClick={toggleDrawer(false)} aria-label="Close menu">
                        <MenuIcon />
                    </IconButton>
                </ListItem>
                <ListItem button onClick={() => {
                    if (isSignedIn) {
                        navigate('/mypage');
                    } else {
                        navigate('/main');
                    }
                    setDrawerOpen(false);
                }}>
                    <IconButton color="inherit" edge="start" aria-label="Home-Drawer">
                        <HomeIcon />
                    </IconButton>
                    <ListItemText
                        primaryTypographyProps={{
                            sx: {
                                fontWeight: isActiveLink(isSignedIn ? '/mypage' : '/main') ? 'bold' : 'normal',
                                textDecoration: isActiveLink(isSignedIn ? '/mypage' : '/main') ? 'underline' : 'none',
                                color: isActiveLink(isSignedIn ? '/mypage' : '/main') ? 'white' : 'inherit',
                                '&:hover': { fontWeight: 'bold', textDecoration: 'underline' }
                            }
                        }}
                    />
                </ListItem>
                <ListItem button onClick={() => {
                    navigate('/announcements');
                    setDrawerOpen(false);
                }}>
                    <ListItemText
                        primary="ΑΝΑΚΟΙΝΩΣΕΙΣ"
                        primaryTypographyProps={{
                            sx: {
                                fontWeight: isActiveLink('/announcements') ? 'bold' : 'normal',
                                textDecoration: isActiveLink('/announcements') ? 'underline' : 'none',
                                color: isActiveLink('/announcements') ? 'white' : 'inherit',
                                '&:hover': { fontWeight: 'bold', textDecoration: 'underline' }
                            }
                        }}
                    />
                </ListItem>
                <ListItem button onClick={() => {
                    localStorage.setItem("redirectToMyTickets", "true");
                    window.location.href = process.env.REACT_APP_TAXIS_LOGIN;
                    setDrawerOpen(false);
                }}>
                    <ListItemText
                        primary="ΤΑ ΑΙΤΗΜΑΤΑ ΜΟΥ"
                        primaryTypographyProps={{
                            sx: {
                                fontWeight: isActiveLink('/mytickets') ? 'bold' : 'normal',
                                textDecoration: isActiveLink('/mytickets') ? 'underline' : 'none',
                                color: isActiveLink('/mytickets') ? 'white' : 'inherit',
                                '&:hover': { fontWeight: 'bold', textDecoration: 'underline' }
                            }
                        }}
                    />
                </ListItem>
                {user && (
                    <>
                        <ListItem button onClick={() => {
                            navigate('/myprofile');
                            setDrawerOpen(false);
                        }}>
                            <ListItemText
                                primary="ΤΟ ΠΡΟΦΙΛ ΜΟΥ"
                                primaryTypographyProps={{
                                    sx: {
                                        fontWeight: isActiveLink('/myprofile') ? 'bold' : 'normal',
                                        textDecoration: isActiveLink('/myprofile') ? 'underline' : 'none',
                                        color: isActiveLink('/myprofile') ? 'white' : 'inherit',
                                        '&:hover': { fontWeight: 'bold', textDecoration: 'underline' }
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItem button onClick={() => {
                            navigate('/ticketform');
                            setDrawerOpen(false);
                        }}>
                            <ListItemText
                                primary="ΝΕΟ ΑΙΤΗΜΑ"
                                primaryTypographyProps={{
                                    sx: {
                                        fontWeight: isActiveLink('/ticketform') ? 'bold' : 'normal',
                                        textDecoration: isActiveLink('/ticketform') ? 'underline' : 'none',
                                        color: isActiveLink('/ticketform') ? 'white' : 'inherit',
                                        '&:hover': { fontWeight: 'bold', textDecoration: 'underline' }
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <Button
                                onClick={handleLogout}
                                variant="outlined"
                                sx={{
                                    borderColor: '#FFFFFF',
                                    color: '#FFFFFF',
                                    '&:hover': { borderColor: '#FFFFFF', color: '#FFFFFF' },
                                    cursor: 'pointer',
                                    display: 'block',
                                    mt: 2,
                                    mb: 2,
                                }}
                            >
                                Αποσυνδεση
                                <ArrowForwardIcon sx={{ ml: 1 }} />
                            </Button>
                        </ListItem>
                    </>
                )}
            </List>
        </Box>
    );

    useEffect(() => {
        const checkScrollTop = () => {
            if (!showScroll && window.scrollY > APP_BAR_HEIGHT) {
                setShowScroll(true);
            } else if (showScroll && window.scrollY <= APP_BAR_HEIGHT) {
                setShowScroll(false);
            }
        };
        window.addEventListener('scroll', checkScrollTop);
        return () => {
            window.removeEventListener('scroll', checkScrollTop);
        };
    }, [showScroll]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed">
                <Toolbar sx={{ justifyContent: { xs: 'center', sm: 'space-between' }, mx: 2 }}>
                    <Box
                        component="img"
                        src="/logo/main.webp"
                        alt="Logo"
                        sx={{ height: isBelow1300 ? 35 : 45, cursor: 'pointer', mx: { xs: 'auto', sm: 0 } }}
                        onClick={handleLogoClick}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <Typography
                            variant="h6"
                            sx={{
                                flexGrow: 1,
                                textDecoration: 'none',
                                color: 'inherit',
                                textAlign: 'center',
                                display: { xs: 'none', sm: 'block' }
                            }}
                        >
                            Ψηφιακή Εξυπηρέτηση
                        </Typography>
                        {!isBelow1300 && user && (
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    aria-label="Home-SignedIn"
                                    onClick={() => {
                                        if (isSignedIn) {
                                            navigate('/mypage');
                                        } else {
                                            navigate('/main');
                                        }
                                    }}
                                    sx={{ mr: 1, mb: 1 }}
                                >
                                    <HomeIcon sx={{ fontSize: '30px' }} />
                                </IconButton>
                                <CustomTypography to="/myprofile">ΤΟ ΠΡΟΦΙΛ ΜΟΥ</CustomTypography>
                                <CustomTypography to="/announcements">ΑΝΑΚΟΙΝΩΣΕΙΣ</CustomTypography>
                                <CustomTypography to="/ticketform">ΝΕΟ ΑΙΤΗΜΑ</CustomTypography>
                                <CustomTypography to="/mytickets">ΤΑ ΑΙΤΗΜΑΤΑ ΜΟΥ</CustomTypography>
                            </Box>
                        )}
                    </Box>
                    {isBelow1300 && (
                        <IconButton
                            color="inherit"
                            edge="start"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                    {!isBelow1300 && (
                        user ? (
                            <Button
                                onClick={handleLogout}
                                variant="outlined"
                                sx={{
                                    borderColor: '#FFFFFF',
                                    color: '#FFFFFF',
                                    '&:hover': { backgroundColor: '#FFFFFF', color: '#003476' },
                                    cursor: 'pointer',
                                    display: { xs: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
                                    alignItems: 'center',
                                    ml: 2,
                                }}
                            >
                                Αποσυνδεση
                                <ArrowForwardIcon sx={{ ml: 1 }} />
                            </Button>
                        ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                    size="large"
                                    color="inherit"
                                    edge="start"
                                    aria-label="Home"
                                    onClick={() => {
                                        if (isSignedIn) {
                                            navigate('/mypage');
                                        } else {
                                            navigate('/main');
                                        }
                                    }}
                                    sx={{ fontSize: '30px', mb: 1 }}
                                >
                                    <HomeIcon sx={{ fontSize: '30px' }}  />
                                </IconButton>
                                <CustomTypography to="/announcements" sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                                    ΑΝΑΚΟΙΝΩΣΕΙΣ
                                </CustomTypography>
                                <Button
                                    onClick={() => {
                                        localStorage.setItem("redirectToMyTickets", "true");
                                        window.location.href = process.env.REACT_APP_TAXIS_LOGIN;
                                    }}
                                    sx={{
                                        backgroundColor: '#ffffff',
                                        color: '#003476',
                                        '&:hover': {
                                            backgroundColor: '#ffffff',
                                            color: '#003476',
                                            textDecoration: 'underline'
                                        },
                                        cursor: 'pointer',
                                        display: { xs: 'none', md: 'block', lg: 'block', xl: 'block' },
                                        ml: 1,
                                        mb: 1,
                                        height: 'fit-content',
                                        alignSelf: 'center'
                                    }}
                                >
                                    ΤΑ ΑΙΤΗΜΑΤΑ ΜΟΥ
                                </Button>
                            </Box>

                        )
                    )}
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
            >
                {drawerContent}
            </Drawer>

            <Box sx={{ mt: `${APP_BAR_HEIGHT}px` }}>
                {children}
            </Box>

            {showScroll && (
                <Button
                    onClick={scrollToTop}
                    aria-label="Scroll to top"
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        right: 16,
                        backgroundColor: '#707070',
                        color: '#FFFFFF',
                        zIndex: 1000,
                        '&:hover': {
                            backgroundColor: '#ffffff',
                            color: '#003476',
                            boxShadow: 'none',
                        },
                        boxShadow: 'none',
                    }}
                >
                    <ArrowUpwardIcon />
                </Button>
            )}
        </Box>
    );
}

export default HeaderWrapper;
