import axios from "axios";
import {EventEmitter} from 'events';
import {jwtDecode} from 'jwt-decode';
import jwtServiceConfig from "app/auth/jwtServiceConfig";
import {axiosApi, axiosNoInterceptors} from "app/configs/axiosConfig";
import {setUser, setToken, logoutUser, selectToken, selectUser} from 'app/store/userSlice';
import {store} from "app/store";
import {decode, encode} from "app/store/encodeDecode";
import {getSpecificUser} from "app/Api Calls/UserCalls";

class JwtService extends EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.response.use(
            (response) => response,
            (err) => {
                return new Promise((resolve, reject) => {
                    if (err && err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        this.emit("onAutoLogout", "Λάθος Username ή Password");
                        this.setSession(null);
                        this.logoutInternal();
                    }
                    reject(err);
                });
            }
        );
    };

    handleAuthentication() {
        const access_token = this.getAccessToken();

        if (!access_token) {
            this.emit("onNoAccessToken");
            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit("onAutoLogin", true);
        } else {
            this.signInWithToken()
                .then((userData) => {
                    const newToken = this.getAccessToken();
                    this.setSession(newToken);
                    this.emit("onAutoLogin", true);
                })
                .catch((error) => {
                    console.error("Error validating access token:", error);
                    this.setSession(null);
                    this.logoutInternal();
                    this.emit("onAutoLogout", "Πρέπει να ξανασυνδεθείτε");
                });
        }
    }

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            axios.post(jwtServiceConfig.signUp, data).then((response) => {
                if (response.data.user) {
                    this.setSession(response.data.token);
                    resolve(response.data.user);
                    this.emit("onLogin", response.data.user);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };

    signInWithToken = async () => {
        const token = this.getAccessToken();

        if (!token || !this.isAuthTokenValid(token)) {
            throw new Error("Invalid or expired token.");
        }

        try {
            await axiosApi.get(jwtServiceConfig.isTokenValid);
            const user = await this.setSession(token);
            return user;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                console.error("Unauthorized access - invalid token:", error);
            } else {
                console.error("Error during sign in with token:", error);
            }
            this.setSession(null);
            this.logoutInternal();
            throw new Error('Error signing in with token.');
        }
    };

    async fetchAndStoreUserDetails() {
        try {
            const user = await getSpecificUser();

            if (user) {
                const updatedUserData = {
                    id: user.id,
                    afm: user.afm,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    phone: user.phone,
                    amka: user.amka
                };

                return updatedUserData;
            }

            return {};
        } catch (error) {
            console.error("Failed to fetch user details:", error);
            throw new Error('Failed to fetch user details');
        }
    }

    signInWithTaxis = async (access_token) => {
        try {
            if (!this.isAuthTokenValid(access_token)) {
                throw new Error("Token is invalid");
            }

            const user = await this.setSession(access_token);
            return user;
        } catch (error) {
            console.error("Error during sign in with Taxis:", error);
            throw error;
        }
    };

    setSession = async (access_token) => {
        if (access_token) {
            let decodedToken;
            try {
                decodedToken = jwtDecode(access_token);
            } catch (error) {
                console.error("Failed to decode JWT token:", error);
                await this.setSession(null);
                return null;
            }

            const [firstName, lastName] = decodedToken.userDetails.username.split(',');

            const user = {
                firstName: firstName,
                lastName: lastName,
                email: decodedToken.userDetails.email,
                id: decodedToken.userDetails.id,
                afm: decodedToken.userDetails.afm,
                roles: decodedToken.userDetails.roles.map(role => role.roleName)
            };

            const encodedToken = encode(access_token);
            await store.dispatch(setToken(encodedToken));
            await store.dispatch(setUser(user));
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;

            const updatedUser = this.getUser();

            return updatedUser ? updatedUser : user;
        } else {
            await store.dispatch(setToken(null));
            await store.dispatch(setUser({
                firstName: null,
                lastName: null,
                email: null,
                roleName: null,
                id: null,
                afm: null,
                roles: [],
            }));
            delete axios.defaults.headers.common.Authorization;

            return null;
        }
    };

    logout = (message) => {
        this.clearPersistedState();
        store.dispatch(logoutUser());
        this.emit("onLogout", message);
    };

    logoutInternal = () => {
        this.clearPersistedState();
        store.dispatch(logoutUser());
    };

    clearPersistedState() {
        const persistedState = JSON.parse(sessionStorage.getItem('persist:store'));
        if (persistedState) {
            const userState = JSON.parse(persistedState.user);
            userState.user = null;
            userState.fetchedUser = null;
            userState.token = null;
            persistedState.user = JSON.stringify(userState);
            sessionStorage.setItem('persist:store', JSON.stringify(persistedState));
        }
    }

    isAuthTokenValid = (access_token) => {
        try {
            const decoded = jwtDecode(access_token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp < currentTime) {
                console.warn("access token expired");
                return false;
            }
            return true;
        } catch (err) {
            console.warn("Invalid access token");
            return false;
        }
    };

    getAccessToken = () => {
        const state = store.getState();
        const token = selectToken(state);
        return token ? decode(token) : null;
    };

    getUser = () => {
        const state = store.getState();
        const user = selectUser(state);
        return user ? JSON.parse(decode(user)) : null;
    };

}

export const jwtService = new JwtService();
