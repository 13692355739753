import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TaxisPostCode from "app/Api Calls/TaxisPostCode";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { jwtService } from "app/auth/jwtService";
import { showMessage } from "app/store/messageSlice";
import { setErrorMessage, setShouldRedirect } from "app/store/userSlice";
import {useAuth} from "app/auth/AuthContext";

function Taxis() {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { setIsAuthenticated } = useAuth();

    useEffect(() => {
        const expectedPath = '/wp-json/webticket1555/v1/aauth/';
        const queryParams = new URLSearchParams(location.search);
        const codeFromURL = queryParams.get("code");

        if (location.pathname !== expectedPath) return;

        const shouldRedirect = localStorage.getItem("shouldRedirect") === "true";

        if (codeFromURL && !shouldRedirect) {
            localStorage.setItem("codeFromRedirect", codeFromURL);
            localStorage.setItem("shouldRedirect", "true");
            const taxisLogoutURL = `${process.env.REACT_APP_TAXIS_REDIRECT_LOGOUT_URL}${codeFromURL}`;
            window.location.href = taxisLogoutURL;
            dispatch(setShouldRedirect(true));
            return;
        }

        const storedCode = localStorage.getItem("codeFromRedirect");

        if (storedCode && shouldRedirect) {
            setLoading(true);
            TaxisPostCode(storedCode)
                .then(async (data) => {
                    if (data) {
                        try {
                            jwtService.signInWithTaxis(data.accessToken)
                                .then((user) => {
                                    if (user) {
                                        setIsAuthenticated(true);
                                        const redirectToProfile = localStorage.getItem("redirectToMyTickets") === "true";
                                        if (redirectToProfile) {
                                            localStorage.removeItem("redirectToMyTickets");
                                            navigate("/mytickets");
                                        } else {
                                            navigate("/ticketform");
                                        }
                                    } else {
                                        handleAuthError("User not found after Taxis login.");
                                    }
                                })
                                .catch((error) => {
                                    handleAuthError("Η Είσοδος με Taxis απέτυχε", error);
                                });
                        } catch (error) {
                            handleAuthError("Η Είσοδος με Taxis απέτυχε", error);
                        }
                    }
                })
                .catch((error) => {
                    handleAuthError("Υπήρξε πρόβλημα με την απόκριση της υπηρεσίας Taxis.", error);
                })
                .finally(() => {
                    setLoading(false);
                    resetLocalStorage();
                });
        }
    }, [location, navigate, dispatch]);
    const handleAuthError = (message, error) => {
        console.error("Error:", error);
        dispatch(showMessage({
            message,
            autoHideDuration: 2000,
            anchorOrigin: {
                vertical: "top",
                horizontal: "center",
            },
            variant: "error",
        }));
        dispatch(setErrorMessage(message));
        resetLocalStorage();
        navigate("/main");
    };

    const resetLocalStorage = () => {
        localStorage.setItem("shouldRedirect", "false");
        localStorage.setItem("codeFromRedirect", "");
    };

    return (
        <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {loading && (
                <div style={{ textAlign: 'center', border: '1px solid #e0e0e0', borderRadius: '8px', padding: '16px', width: '300px', backgroundColor: '#fff' }}>
                    <img src={`${process.env.PUBLIC_URL}/logo/govgrthumb.png`} alt="Logo" style={{ maxWidth: '100%' }} />
                    <p>Αναμονή για σύνδεση μέ κωδικούς Taxis</p>
                    <div style={{ marginTop: '16px' }}>
                        <CircularProgress />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Taxis;
