import React, {useState, useRef, useEffect} from 'react';
import {Container, Grid, TextField, Typography, Card, CardContent, Link} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {styled} from '@mui/system';
import {faqItems} from 'app/main/KnowledgeBase/faqitems';

const RootContainer = styled(Container)(({theme}) => ({
    flexGrow: 1,
    marginBottom: '10px',
    marginTop: '70px',
}));

const SearchField = styled(TextField)(({theme}) => ({
    width: '100%',
}));

const SectionLink = styled(Link)(({theme}) => ({
    textDecoration: 'none',
    color: 'inherit',
}));

const FaqCard = styled(Card)(({theme}) => ({
    marginBottom: theme.spacing(3),
}));

const scrollWithOffset = (el) => {
    const headerOffset = 64;
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
    const yOffset = yCoordinate - headerOffset;

    window.scrollTo({
        top: yOffset,
        behavior: 'smooth',
    });
};

const handleClick = (ref) => {
    if (ref && ref.current) {
        scrollWithOffset(ref.current);
    }
};

const highlightText = (text, query) => {
    if (!query || typeof text !== 'string') return text;

    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ?
            <span key={index} style={{backgroundColor: 'yellow'}}>{part}</span> : part
    );
};

const highlightNestedContent = (content, query) => {
    if (typeof content === 'string') {
        return highlightText(content, query);
    }

    if (Array.isArray(content)) {
        return content.map((child, index) => (
            <React.Fragment key={index}>{highlightNestedContent(child, query)}</React.Fragment>
        ));
    }

    if (typeof content === 'object' && content.props) {
        return React.cloneElement(content, {
            children: highlightNestedContent(content.props.children, query)
        });
    }

    return content;
};

const searchInContent = (content, query) => {
    if (!content) return false;

    if (typeof content === 'string') {
        return content.toLowerCase().includes(query);
    }

    if (Array.isArray(content)) {
        return content.some((child) => searchInContent(child, query));
    }

    if (typeof content === 'object' && content.props) {
        return searchInContent(content.props.children, query);
    }

    return false;
};

const Odigies = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const faqRefs = useRef([]);
    const debounceTimeout = useRef(null);

    faqRefs.current = faqItems.map((_, index) => faqRefs.current[index] ?? React.createRef());

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchQuery(value);

        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
            if (value) {
                const firstMatchedIndex = faqItems.findIndex(
                    (item) =>
                        item.title.toLowerCase().includes(value) ||
                        searchInContent(item.content, value)
                );

                if (firstMatchedIndex !== -1) {
                    handleClick(faqRefs.current[firstMatchedIndex]);
                }
            }
        }, 300); // Adjust the debounce delay as needed
    };

    const filteredFaqItems = faqItems.filter(
        (item) =>
            item.title.toLowerCase().includes(searchQuery) ||
            searchInContent(item.content, searchQuery)
    );

    const renderFaqItems = (items) => {
        return items.map((item, index) => {
            const isLast = index === items.length - 1;
            return (
                <FaqCard
                    key={item.id}
                    ref={faqRefs.current[index]}
                    style={{marginBottom: isLast ? 0 : 24}}
                >
                    <CardContent>
                        <Typography variant="h5" className="tv-faq-title">
                            {highlightText(item.title, searchQuery)}
                        </Typography>
                        <div className="tv-faqs-answer-tm feature_part_text">
                            {highlightNestedContent(item.content, searchQuery)}
                        </div>
                    </CardContent>
                </FaqCard>
            );
        });
    };

    return (
        <RootContainer>
            <Grid container spacing={3}>
                <Grid item md={4}>
                    <div
                        style={{
                            position: 'sticky',
                            top: '70px',
                            zIndex: 1000,
                            backgroundColor: 'white',
                            padding: '20px',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                        }}
                    >
                        <Typography variant="h6" className="tv-faqs-title-tm" gutterBottom>
                            Περιεχόμενα
                        </Typography>
                        <SearchField
                            id="search-field"
                            type="search"
                            placeholder="Αναζήτηση στον Οδηγό"
                            variant="outlined"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: <SearchIcon position="start"/>,
                            }}
                            fullWidth
                            style={{marginBottom: '20px'}}
                        />
                        <ul className="tv-faqs-filter-tm" style={{listStyleType: 'none', padding: 0}}>
                            {faqItems.map((item, index) => (
                                <li key={item.id} style={{marginBottom: '10px'}}>
                                    <SectionLink
                                        href={`#${item.id}`}
                                        onClick={() => handleClick(faqRefs.current[index])}
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{
                                                color: '#333',
                                                textDecoration: 'none',
                                                padding: '8px',
                                                display: 'block',
                                                borderRadius: '4px',
                                                transition: 'background-color 0.3s',
                                            }}
                                            onMouseEnter={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
                                            onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
                                        >
                                            {item.title}
                                        </Typography>
                                    </SectionLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Grid>

                <Grid item md={8} className="faq-main-container">
                    {searchQuery && filteredFaqItems.length === 0 && (
                        <Typography variant="h6" color="error" align="center" style={{marginBottom: '20px'}}>
                            Δεν βρέθηκαν αποτελέσματα για "{searchQuery}"
                        </Typography>
                    )}
                    {renderFaqItems(filteredFaqItems)}
                </Grid>
            </Grid>
        </RootContainer>
    );
};

export default Odigies;
