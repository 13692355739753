// CustomTypography.js
import React from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom';

const CustomTypography = ({ to, children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isActiveLink = (path) => location.pathname === path;

    return (
        <Typography
            variant="body2"
            sx={{
                mx: 1,
                cursor: 'pointer',
                color: isActiveLink(to) ? 'white' : 'inherit',
                fontWeight: isActiveLink(to) ? '900' : 'normal',
                textDecoration: 'none',
                position: 'relative',
                overflow: 'hidden',
                paddingBottom: '0.4em',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '0.2em',
                    backgroundColor: 'white',
                    opacity: isActiveLink(to) ? 1 : 0,
                    transform: isActiveLink(to) ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)',
                    transition: 'opacity 300ms, transform 300ms',
                },
                '&:hover::after': {
                    opacity: 1,
                    transform: 'translate3d(0, 0, 0)',
                },
            }}
            onClick={() => navigate(to)}
        >
            {children}
        </Typography>
    );
};

export default CustomTypography;
