import React, { useEffect, useState, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { jwtService } from 'app/auth/jwtService';
import { setUser } from 'app/store/userSlice';
import { showMessage } from 'app/store/messageSlice';
import SplashScreen from 'app/auth/SplashScreen';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeAuth = () => {
      jwtService
          .signInWithToken()
          .then((userData) => {
            success(userData);
          })
          .catch((error) => {
            pass(error.message);
            jwtService.logoutInternal();
          });
    };

    jwtService.on('onAutoLogin', initializeAuth);

    jwtService.on('onLogin', (userData) => {
      success(userData, 'Συνδεθήκατε');
    });

    jwtService.on('onLogout', (message) => {
      pass(message);
    });

    jwtService.on('onAutoLogout', (message) => {
      pass(message);
      jwtService.logoutInternal();
    });

    jwtService.on('onNoAccessToken', (message) => {
      pass(message);
    });

    jwtService.init();

    function success(userData, message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      if (userData) {
        Promise.all([dispatch(setUser(userData))]).then(() => {
          setIsAuthenticated(true);
        });
      } else {
        pass('Failed to set user data');
      }
    }

    function pass(message) {
      if (message) {
        dispatch(
            showMessage({
              message: message,
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
              },
              variant: '',
            })
        );
      }

      setIsAuthenticated(false);
    }
  }, [dispatch]);

  return isAuthenticated === undefined ? (
      <SplashScreen />
  ) : (
      <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>{children}</AuthContext.Provider>
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
