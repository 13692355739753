import React, {useState} from 'react';
import {Box, Typography, CircularProgress, Grid} from '@mui/material';
import {downloadFile} from 'app/Api Calls/TicketApi';
import {FileLogo} from 'app/main/SignedIn/Reusable Components/FileLogo';

const TicketFiles = ({files, filterType, title}) => {
    const [downloading, setDownloading] = useState({});

    if (!files || files.length === 0) {
        return null;
    }

    const handleDownload = async (fileId, fileName, fileExtension) => {
        setDownloading((prev) => ({...prev, [fileId]: true}));
        try {
            const url = await downloadFile(fileId);

            // Fetch the file as a blob
            const fileResponse = await fetch(url);
            const blob = await fileResponse.blob();
            const blobUrl = URL.createObjectURL(blob);
            const completeFileName = `${fileName}.${fileExtension}`;
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', completeFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
        setDownloading((prev) => ({...prev, [fileId]: false}));
    };

    const filteredFiles = files.filter(file =>
        (filterType === 'beneficiary' && file.uploadedByBeneficiaryId) ||
        (filterType === 'representative' && !file.uploadedByBeneficiaryId && file.isPublished === 1)
    );

    if (filteredFiles.length === 0) {
        return null;
    }

    return (
        <Box sx={{width: "100%", mb: 2}}>
            <Typography
                variant="body2"
                sx={{fontSize: '15px', fontWeight: 600, mb: 1, color: "#003476"}}
            >
                {title}
            </Typography>
            <Grid container spacing={2}>
                {filteredFiles.map((file) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={file.id}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                p: 2,
                                border: '1px solid #e0e0e0',
                                borderRadius: '4px',
                                boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                                transition: 'box-shadow 0.3s ease-in-out',
                                cursor: downloading[file.id] ? 'not-allowed' : 'pointer',
                                '&:hover': {
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                },
                                position: 'relative',
                            }}
                            onClick={() => !downloading[file.id] && handleDownload(file.id, file.originFileName, file.extension)}
                        >
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 1,
                                justifyContent: 'center',
                                width: '100%',
                                position: 'relative'
                            }}>
                                <FileLogo extension={file.extension}/>
                                <Typography
                                    variant="body2"
                                    sx={{fontSize: '14px', color: 'black', textAlign: 'center', ml: 1}}
                                >
                                    {file.originFileName}
                                </Typography>
                                {downloading[file.id] && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            ml: 1,
                                        }}
                                    >
                                        <CircularProgress size={20} />
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default TicketFiles;
