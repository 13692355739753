import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import React, {forwardRef} from "react";
import {TextField as MuiTextField} from "@mui/material";

export const validatePhoneNumber = (value, type) => {
    const phoneNumber = parsePhoneNumberFromString(value, 'GR');

    if (phoneNumber && phoneNumber.isValid()) {
        const numberType = phoneNumber.getType();
        if (type === 'landline' && numberType === 'MOBILE') {
            return 'Παρακαλώ εισάγετε ένα έγκυρο αριθμό σταθερού';
        } else if (type === 'mobile' && numberType !== 'MOBILE') {
            return 'Παρακαλώ εισάγετε ένα έγκυρο αριθμό κινητού';
        }
        return '';
    }

    return 'Παρακαλώ εισάγετε ένα έγκυρο αριθμό';
};

// utils.js

export const normalizePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const phoneObj = parsePhoneNumberFromString(phoneNumber, 'GR');
    return phoneObj ? phoneObj.nationalNumber : phoneNumber.replace(/^(\+30|0030)?/, '');
};

export const CustomTextField = forwardRef((props, ref) => (
    <MuiTextField
        {...props}
        InputProps={{
            ...props.InputProps,
            sx: {
                backgroundColor: "#f0f0f0",
                height: '40px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: '4px',
                '& fieldset': {
                    border: 'none',
                },
                '&:hover fieldset': {
                    border: 'none',
                },
                '&.Mui-focused fieldset': {
                    border: 'none',
                },
                ...props.InputProps?.sx,
            },
            inputRef: ref,
        }}
        sx={{ mt: 2, ...props.sx }}
    />
));
