import React from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'app/auth/AuthContext';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Error404Page = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const styles = {
    root: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: isMobile ? '20px' : '40px',
      textAlign: 'center',
    },
    errorCode: {
      fontSize: isMobile ? '12rem' : '27rem',
      color: '#003476',
    },
    button: {
      marginRight: isMobile ? '5px' : '10px',
      marginBottom: isMobile ? '10px' : '0',
      backgroundColor: '#003476',
      color: 'white',
      width: isMobile ? '100%' : 'auto',
    },
  };

  return (
      <Box style={styles.root}>
        <Typography style={styles.errorCode}><strong>404</strong></Typography>
        <Typography variant="h6">Η σελίδα που ζητήσατε δεν βρέθηκε.</Typography>
        <Box mt={3} display="flex" flexDirection={isMobile ? 'column' : 'row'}>
          <Button
              variant="contained"
              style={styles.button}
              onClick={() => navigate(isAuthenticated ? '/mypage' : '/main')}
          >
            Αρχική
          </Button>
          <Button
              variant="contained"
              style={styles.button}
              onClick={() => navigate('/sign-in')}
              disabled={isAuthenticated}
          >
            Είσοδος
          </Button>
        </Box>
      </Box>
  );
};

export default Error404Page;
