import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';

function CarrierList({ carriers, selectedCarrierId, onCarrierSelect, sx }) {
    return (
        <List sx={{
            width: '100%',
            bgcolor: '#003476',
            margin: 0,
            borderColor: 'divider',
            overflowY: 'auto',
            height: '100%',
            ...sx
        }}>
            {carriers.map((carrier, index) => (
                <ListItem
                    key={index}
                    onClick={() => onCarrierSelect(carrier)}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': { bgcolor: '#ffffff', color: '#000000' },
                        bgcolor: selectedCarrierId === carrier.id ? '#ffffff' : '#003476',
                        color: selectedCarrierId === carrier.id ? 'black' : '#ffffff',
                        fontWeight: selectedCarrierId === carrier.id ? 'bold' : 'normal',
                    }}
                >
                    <ListItemText
                        primary={carrier.title}
                        primaryTypographyProps={{
                            fontSize: '1.25rem',
                        }}
                    />
                </ListItem>
            ))}
        </List>
    );
}

export default CarrierList;
