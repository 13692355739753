import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography, IconButton } from '@mui/material';
import { CheckCircleOutline, Close as CloseIcon } from '@mui/icons-material';

const SubmissionIndicator = ({ success, onClose }) => {
    useEffect(() => {
        if (success) {
            const timer = setTimeout(() => {
                onClose();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [success, onClose]);

    return (
        <Box sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
        }}>
            <Box sx={{
                position: 'relative',
                backgroundColor: '#ffffff',
                padding: 4,
                borderRadius: 2,
                boxShadow: 3,
                textAlign: 'center',
            }}>
                {success && (
                    <IconButton
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                )}
                {success ? (
                    <Box sx={{ textAlign: 'center' }}>
                        <CheckCircleOutline sx={{ color: 'green', fontSize: 60 }} />
                        <Typography variant="h6" sx={{ mt: 2 }}>Η υποβολή σας ήταν επιτυχής</Typography>
                    </Box>
                ) : (
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" sx={{ mb: 2 }}>Δημιουργία αιτήματος</Typography>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default SubmissionIndicator;
