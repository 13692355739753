import {axiosApi, axiosNoInterceptors} from "app/configs/axiosConfig";

// POST Create Announcement


// GET Download Announcement File
export const getAnnouncementFileDownload = async (fileId) => {
    try {
        const response = await axiosNoInterceptors.get(`/public/announcement/file/download/${fileId}`);
        return response.data.url;
    } catch (error) {
        throw error;
    }
};

export const getAnnouncementFiles = async (announcement) => {
    try {
        const response = await axiosNoInterceptors.get(`/public/announcement/file/${announcement}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};


// GET Retrieve Announcement
export const getAnnouncement = async (announcementId) => {
    try {
        const response = await axiosNoInterceptors.get(`/public/announcement/${announcementId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

// GET Retrieve Announcements by Carrier ID
export const getAnnouncementsByCarrierId = async (carrierId) => {
    try {
        const response = await axiosNoInterceptors.get(`/public/announcement/byCarrierId/${carrierId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

