import {createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit';
import { encode, decode } from './encodeDecode';
import { getSpecificUser, updateUser, createUser } from 'app/Api Calls/UserCalls';

export const fetchSpecificUser = createAsyncThunk('user/fetchSpecificUser', async () => {
    const response = await getSpecificUser();
    return { data: response.data, status: response.status };
});

export const updateUserThunk = createAsyncThunk('user/updateUser', async (user) => {
    const response = await updateUser(user);
    return response;
});

export const createUserThunk = createAsyncThunk('user/createUser', async (user) => {
    const response = await createUser(user);
    return response;
});

const initialState = {
    fetchedUser: null,
    user: null,
    token: null,
    errorMessage: null,
    shouldRedirect: false,
    taxisCode: null,
    status: null
};

export const userInitialState = initialState;

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = encode(JSON.stringify(action.payload));
        },
        setToken: (state, action) => {
            state.token = encode(action.payload);
        },
        setFetchedUser: (state, action) => {
            state.fetchedUser = encode(JSON.stringify(action.payload));
        },
        logoutUser: () => initialState,
        userLoggedOut: (state) => initialState,
        setErrorMessage: (state, action) => {
            state.errorMessage = action.payload;
        },
        setShouldRedirect: (state, action) => {
            state.shouldRedirect = action.payload;
        },
        setTaxisCode: (state, action) => {
            state.taxisCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSpecificUser.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSpecificUser.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchedUser = encode(JSON.stringify(action.payload));
            })
            .addCase(fetchSpecificUser.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateUserThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateUserThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchedUser = encode(JSON.stringify(action.payload));
            })
            .addCase(updateUserThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createUserThunk.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createUserThunk.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.fetchedUser = encode(JSON.stringify(action.payload));
            })
            .addCase(createUserThunk.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const {
    userLoggedOut,
    setErrorMessage,
    setShouldRedirect,
    setTaxisCode,
    setUser,
    setToken,
    logoutUser,
    setFetchedUser
} = userSlice.actions;


export const selectToken = (state) => {
    return state.user.token ? decode(state.user.token) : null;
};

export const selectUser = (state) => {
    return state.user.user;
};

export const selectFetchedUser = createSelector(
    (state) => state.user.fetchedUser,
    (fetchedUser) => (fetchedUser ? JSON.parse(decode(fetchedUser)) : null)
);


export default userSlice.reducer;
