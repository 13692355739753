import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Container,
    Pagination,
    PaginationItem,
    Button,
} from '@mui/material';
import {fetchLatestNote, fetchTickets, setPage} from 'app/store/ticketSlice';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from 'themes/theme';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {useNavigate} from 'react-router-dom';
import {getThematicById} from 'app/Api Calls/KnowledgebaseCalls';
import {fetchTicketFiles} from "app/Api Calls/TicketApi";
import NoTickets from "app/main/SignedIn/MyTickets/NoTickets";
import TicketsTable from "app/main/SignedIn/MyTickets/TicketsTable";
import Loading from "app/main/SignedIn/MyTickets/Loading";


const MyTicketsPage = () => {
    const dispatch = useDispatch();
    const { tickets, loading, page, totalPages, ticketNotes, noteLoading } = useSelector((state) => state.tickets);
    const [expandedRow, setExpandedRow] = useState(null);
    const [thematics, setThematics] = useState({});
    const navigate = useNavigate();
    const [thematicsLoading, setThematicsLoading] = useState(false);
    const [loadingTicketId, setLoadingTicketId] = useState(null);
    const [ticketFiles, setTicketFiles] = useState({});

    useEffect(() => {
        dispatch(fetchTickets({ page: page - 1, size: 10 }));
    }, [page, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(setPage(1));
        };
    }, [dispatch]);

    const fetchAllThematics = async (initialId) => {
        let fetchedThematics = [];
        let currentId = initialId;

        // Fetch the initial thematic by its own ID
        const initialThematic = await getThematicById(currentId);
        if (!initialThematic) {
            return fetchedThematics; // Return empty array if initial thematic is not found
        }

        // Set the current ID to the parent ID of the initial thematic to start fetching parents
        currentId = initialThematic.parentId;

        // Fetch parent thematics
        while (currentId) {
            const thematicData = await getThematicById(currentId);
            if (thematicData) {
                fetchedThematics.unshift(thematicData); // Insert the parent thematic at the beginning of the array

                // Check if the parentId is valid (not zero or null)
                if (thematicData.parentId && thematicData.parentId !== 0) {
                    currentId = thematicData.parentId;
                } else {
                    currentId = null;
                }
            } else {
                currentId = null;
            }
        }

        // Add the initial thematic to the end of the list
        fetchedThematics.push(initialThematic);

        return fetchedThematics;
    };

    const handleRowClick = async (ticket) => {
        const ticketId = ticket?.id;
        setExpandedRow(expandedRow === ticketId ? null : ticketId);

        setLoadingTicketId(ticketId);

        const promises = [];

        if (!ticketNotes[ticketId] && ticket.ticketStatus.id === 5) {
            promises.push(dispatch(fetchLatestNote(ticketId)));
        }

        if (!thematics[ticketId]) {
            setThematicsLoading(true);
            const thematicPromise = fetchAllThematics(ticket.thematic.id).then((completeThematics) => {
                setThematics((prevThematics) => ({
                    ...prevThematics,
                    [ticketId]: completeThematics,
                }));
                setThematicsLoading(false);
            });
            promises.push(thematicPromise);
        }

        if (!ticketFiles[ticketId]) {
            const filePromise = fetchTicketFiles(ticketId).then((files) => {
                setTicketFiles((prevFiles) => ({
                    ...prevFiles,
                    [ticketId]: files,
                }));
            });
            promises.push(filePromise);
        }

        await Promise.all(promises);
        setLoadingTicketId(null);
    };

    const handlePageChange = (event, value) => {
        dispatch(setPage(value));
        window.scrollTo(0, 0);
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <Container sx={{ marginTop: '80px' }}>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#003476',
                    color: '#ffffff',
                    mb: 2,
                    boxShadow: 'none',
                    fontSize: '15px',
                    '&:hover': {
                        boxShadow: 3,
                    },
                }}
                startIcon={<ArrowCircleLeftIcon />}
                onClick={() => navigate('/mypage')}
            >
                Πισω
            </Button>
            {tickets.length === 0 ? (
                <NoTickets />
            ) : (
                <TicketsTable
                    tickets={tickets}
                    expandedRow={expandedRow}
                    handleRowClick={handleRowClick}
                    thematics={thematics}
                    ticketNotes={ticketNotes}
                    ticketFiles={ticketFiles}
                    thematicsLoading={thematicsLoading}
                    noteLoading={noteLoading}
                    loadingTicketId={loadingTicketId}
                />
            )}
            <Pagination
                count={totalPages}
                page={page}
                color="primary"
                onChange={handlePageChange}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 4,
                    marginBottom: 4,
                    '& .MuiPaginationItem-root': {
                        backgroundColor: 'white',
                        border: '1px solid #d0d0d0',
                        borderRadius: '4px',
                        color: '#003476',
                        height: '30px', // Set a fixed height
                        '&:hover': {
                            backgroundColor: '#f0f0f0',
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#003476',
                            color: 'white',
                            borderColor: '#003476',
                        },
                    },
                    '& .MuiPaginationItem-ellipsis': {
                        height: '30px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '4px',
                        border: '1px solid #d0d0d0',
                    },
                }}
                siblingCount={0}
                boundaryCount={1}
                renderItem={(item) => (
                    <PaginationItem
                        {...item}
                        components={{ previous: 'span', next: 'span' }}
                        slots={{
                            previous: () => (
                                <span style={{ fontSize: '15px' }}>
                                    Πίσω
                                </span>
                            ),
                            next: () => (
                                <span style={{ fontSize: '15px' }}>
                                    Εμπρός
                                </span>
                            ),
                        }}
                    />
                )}
            />
        </Container>
    );
};

export default MyTicketsPage;