// faqItems.js
import React from 'react';
import { Typography } from '@mui/material';

export const faqItems = [
    {
        id: 'Faq-1',
        title: '1. ΨΗΦΙΑΚΟ ΕΓΧΕΙΡΙΔΙΟ ΥΠΗΡΕΣΙΩΝ ΚΑΙ ΔΙΑΔΙΚΑΣΙΩΝ',
        content: (
            <>
                <Typography paragraph>
                    Καλώς ήρθατε στο Ψηφιακό Εγχειρίδιο Υπηρεσιών και Διαδικασιών 1555.
                </Typography>
                <Typography paragraph>
                    Αναγνωρίζοντας την υποχρέωσή μας να παρέχουμε αδιαμεσολάβητη, έγκυρη και έγκαιρη πληροφόρηση και
                    σεβόμενοι το δικαίωμά σας να εξυπηρετείστε άμεσα και αποτελεσματικά χωρίς άσκοπες μετακινήσεις
                    και σπατάλη χρόνου, αποτυπώσαμε σε ένα εύχρηστο και φιλικό περιβάλλον όλες τις απαραίτητες
                    πληροφορίες για κάθε υπηρεσία που εκ του νόμου παρέχει το Υπουργείο Εργασίας σε πολίτες ή/και
                    επιχειρήσεις.
                </Typography>
                <Typography paragraph>
                    Παράλληλα, μπορείτε να επιλέξετε την αναδρομολόγησή σας στις σελίδες gov.gr και support.gov.gr,
                    αντίστοιχα, για κάθε παρεχόμενη υπηρεσία, η οποία φιλοξενείται στην ενιαία κυβερνητική πύλη
                    ψηφιακής εξυπηρέτησης (gov.gr) και υποστηρίζεται μέσω της ενιαίας κυβερνητικής υπηρεσίας
                    εξυπηρέτησης στη χρήση των ψηφιακών υπηρεσιών (support.gov.gr).
                </Typography>
            </>
        ),
    },
    {
        id: 'Faq-2',
        title: '2. ΑΝΑΖΗΤΗΣΗ ΥΠΗΡΕΣΙΑΣ',
        content: (
            <>
                <Typography variant="h6">
                    Εύκολη και γρήγορη αναζήτηση με πολλούς διαφορετικούς τρόπους!
                </Typography>
                <Typography paragraph>
                    Μέσω της αρχικής σελίδας του Οδηγού του Πολίτη έχετε κατ’ αρχήν τη δυνατότητα να αναζητήσετε και
                    να εντοπίσετε την υπηρεσία που σας ενδιαφέρει.
                </Typography>
                <Typography paragraph>
                    Η αναζήτηση μιας υπηρεσίας μπορεί να γίνει με έναν από τους ακόλουθους τρόπους:
                </Typography>
                <Typography paragraph>
                    Α) με τη χρήση της ειδικής μηχανής αναζήτησης στο επάνω τμήμα της αρχικής σελίδας
                </Typography>
                <Typography paragraph>
                    Β) με συνδυασμό κριτηρίων που αποτυπώνουν την υπαγωγή των παρεχόμενων υπηρεσιών σε ευρύτερες
                    κατηγορίες ανάλογα με το θέμα τους, την ιδιότητα των εξυπηρετούμενων ή/και τον τρόπο παροχής
                    τους.
                </Typography>
                <Typography variant="h6">Παράδειγμα Β1:</Typography>
                <Typography paragraph>
                    Εάν είστε μισθωτός και ενδιαφέρεστε για Αναγγελία λύσης σύμβασης, μπορείτε να επιλέξετε με βάση
                    την ιδιότητα σας τις υπηρεσίες «ΓΙΑ ΤΟΝ ΠΟΛΙΤΗ» και στην συνέχεια την υποκατηγορία «ΜΙΣΘΩΤΟΙ».
                    Με αυτόν τον τρόπο θα εμφανιστεί στη σελίδα του Οδηγού του Πολίτη το υποσύνολο των παρεχόμενων
                    υπηρεσιών που αντιστοιχούν στην ιδιότητα με την οποία κάνατε την αναζήτηση. Εκεί θα μπορέσετε
                    μέσα από την σχετική λίστα να εντοπίσετε και να επιλέξετε να πληροφορηθείτε για την υπηρεσία
                    «Ηλεκτρονική Υπηρεσία – Αναγγελία λύσης σύμβασης (Εργάνη-Ε7)».
                </Typography>
                <Typography variant="h6">Παράδειγμα Β2:</Typography>
                <Typography paragraph>
                    Εάν ενδιαφέρεστε για Αναγγελία λύσης σύμβασης, μπορείτε πατώντας επάνω στην επιλογή «ΑΝΑ
                    ΚΑΤΗΓΟΡΙΑ» να δείτε τις διαθέσιμες υποκατηγορίες παρεχόμενων υπηρεσιών ανά Φορέα (ΣΕΠΕ, ΕΦΚΑ,
                    ΟΑΕΔ, ΟΠΕΚΑ). Στο πλαίσιο του παραδείγματος επιλέγετε «ΟΑΕΔ» και στην συνέχεια την υποκατηγορία
                    που σας αφορά και εκεί θα μπορέσετε μέσα από τη σχετική λίστα να εντοπίσετε και να επιλέξετε να
                    πληροφορηθείτε για την υπηρεσία «Ηλεκτρονική Υπηρεσία – Αναγγελία λύσης σύμβασης (Εργάνη-Ε7)».
                </Typography>
            </>
        ),
    },
    {
        id: 'Faq-3',
        title: '3. ΒΑΣΙΚΕΣ ΠΛΗΡΟΦΟΡΙΕΣ ΥΠΗΡΕΣΙΑΣ',
        content: (
            <>
                <Typography variant="h6">Τι, Που, Πως, Πότε</Typography>
                <Typography paragraph>
                    Έχοντας, ήδη, εντοπίσει την υπηρεσία που σας ενδιαφέρει αποκτάτε πρόσβαση στο σύνολο των
                    πληροφοριών που θα απλοποιήσουν και θα διευκολύνουν την εξυπηρέτησή σας.
                </Typography>
                <Typography paragraph>
                    Πληροφορίες αναφορικά με το πώς, πού ή πότε θα εξυπηρετηθείτε αποτυπώνονται διαρθρωμένες σε
                    ξεχωριστές καρτέλες για δική σας διευκόλυνση.
                </Typography>
                <Typography paragraph>
                    Ειδικότερα μέσα από την ενότητα <b>Βασικές Πληροφορίες</b> μπορείτε να μάθετε:
                </Typography>
                <Typography paragraph>
                    Α) τον αναμενόμενο και τον μέγιστο χρόνο εξυπηρέτησης για την υπηρεσία που έχετε επιλέξει
                </Typography>
                <Typography paragraph>
                    Β) αν η υποβολή της αίτησης απαιτεί επίσκεψη σε κάποιο από τα σημεία εξυπηρέτησης ή αν μπορεί να
                    γίνει ψηφιακά,
                </Typography>
                <Typography paragraph>
                    Γ) ποια είναι η αίτηση που θα χρειαστεί να συμπληρώσετε (δίνεται η δυνατότητα λήψης του
                    αρχείου).
                </Typography>
                <Typography paragraph>
                    Στη συνέχεια, μέσα από την ενότητα <b>Αναλυτική Περιγραφή</b> μπορείτε να ενημερωθείτε για το
                    συνολικό κύκλο εξυπηρέτησης, ξεκινώντας με τα δικαιολογητικά που θα πρέπει εκ του νόμου να
                    συνοδεύουν την υποβολή της αίτησης σας και συνεχίζοντας με πληροφορίες που σχετίζονται με τη
                    νομοθεσία που διέπει την παροχή της συγκεκριμένης υπηρεσίας καθώς και οποιαδήποτε οδηγία,
                    σημείωση ή παρατήρηση μπορεί να απλοποιήσει και να βελτιώσει την εξυπηρέτησή σας.
                </Typography>
            </>
        ),
    },
    {
        id: 'Faq-4',
        title: '4. ΨΗΦΙΑΚΗ ΥΠΟΒΟΛΗ',
        content: (
            <>
                <Typography paragraph>
                    Στην ενότητα <b>Ψηφιακή Υποβολή</b> έχετε τη δυνατότητα να υποβάλλεται ψηφιακά την αίτησή σας,
                    μέσω του gov.gr όπου σας ανακατευθύνει ο σύνδεσμος που θα επιλέξετε <b>Υποβολή Αίτησης</b>.
                </Typography>
            </>
        ),
    },
    {
        id: 'Faq-5',
        title: '5. ΠΡΟΣΩΠΟΠΟΙΗΜΕΝΗ ΠΛΗΡΟΦΟΡΗΣΗ',
        content: (
            <>
                <Typography variant="h6">Από την Διοικητική Πληροφόρηση στην Άμεση, Προσωποποιημένη
                    Εξυπηρέτηση</Typography>
                <Typography paragraph>
                    Έχοντας συλλέξει ήδη κάθε απαραίτητη πληροφορία, μπορείτε πλέον να αξιοποιήσετε τις πρόσθετες
                    δυνατότητες του Οδηγού του Πολίτη και να απολαύσετε Άμεση, Προσωποποιημένη Εξυπηρέτηση.
                </Typography>
                <Typography paragraph>
                    Αφού εισέλθετε στον Προσωπικό σας Οδηγό, αποκτάτε τις εξής δυνατότητες:
                </Typography>
                <Typography paragraph>
                    Α) να επικαιροποιήσετε / διορθώστε μέσω της επιλογής «Το προφίλ μου» τα προσωπικά σας στοιχεία,
                </Typography>
                <Typography paragraph>
                    Β) να επιλέξετε από το σύνολο των παρεχόμενων υπηρεσιών εκείνες για τις οποίες έχετε ένα
                    αυξημένο ενδιαφέρον προκειμένου να ενημερώνεστε κατά προτεραιότητα για τις εξελίξεις γύρω από
                    αυτές,
                </Typography>
                <Typography paragraph>
                    Γ) να υποβάλλετε ψηφιακά -για όποιες υπηρεσίες δίνεται αυτή η δυνατότητα- μέσα από την ενότητα
                    «Ψηφιακή Υποβολή» την αίτηση σας και να επισυνάψετε τα εκ του νόμου αναγκαία δικαιολογητικά,
                </Typography>
                <Typography paragraph>
                    Απαραίτητη προϋπόθεση για την προσωποποίηση του Οδηγού και την πρόσβαση στις υπηρεσίες
                    προσωποποιημένης εξυπηρέτησης είναι η επιτυχής ολοκλήρωση της διαδικασίας ταυτοποίησης O-auth2 –
                    taxis net authentication.
                </Typography>
                <Typography paragraph>
                    Η διαδικασία αυτή μπορεί να λάβει χώρα,
                </Typography>
                <Typography paragraph>
                    Α) μέσα από το κουμπί «Σύνδεση» στο επάνω δεξιά τμήμα της αρχικής σελίδας του Οδηγού του Πολίτη
                </Typography>
                <Typography paragraph>
                    Β) μέσα από τις ενότητες «Ψηφιακή Υποβολή» και τις ειδικότερες επιλογές «Υποβολή Αίτησης».
                </Typography>
            </>
        ),
    },
];
