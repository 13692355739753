import {Box, Typography} from "@mui/material";

const NoTickets = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Typography variant="h6" sx={{ fontSize: '19px' }}>
            Δεν υπάρχουν αιτήματα
        </Typography>
    </Box>
);

export default NoTickets;