import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
import { jwtService } from 'app/auth/jwtService';
import { useNavigate } from "react-router-dom";
import { useTheme, useMediaQuery } from '@mui/material';

function SignOutPage() {
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setTimeout(() => {
            jwtService.logout("Αποσυνδεθήκατε");
            navigate('/main');
        }, 2500);
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center min-w-0 h-screen" style={{ backgroundColor: '#edf0f6' }}>
            <Paper
                className="flex flex-col items-center justify-center rounded-2xl shadow"
                style={{
                    width: isMobile ? '100%' : '50%',
                    height: isMobile ? '100%' : '50%',
                    padding: '24px'
                }}
            >
                <img
                    src={`${process.env.PUBLIC_URL}/logo/Ypourgeio1555.webp`}
                    alt="logo"
                    style={{
                        maxWidth: isMobile ? '80%' : '50%',
                        maxHeight: isMobile ? '80%' : '50%'
                    }}
                />
                <Typography className="text-2xl font-extrabold tracking-tight leading-tight text-center mt-4">
                    Έχετε Αποσυνδεθεί!
                </Typography>
            </Paper>
        </div>
    );
}

export default SignOutPage;
