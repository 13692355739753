import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "themes/theme";
import {Box, CircularProgress, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import TicketDetails from "app/main/SignedIn/MyTickets/TicketDetails";

const TicketRow = ({
                       ticket,
                       expandedRow,
                       handleRowClick,
                       thematics,
                       ticketNotes,
                       ticketFiles,
                       thematicsLoading,
                       noteLoading,
                       loadingTicketId
                   }) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            <TableRow
                onClick={() => handleRowClick(ticket)}
                sx={{
                    cursor: 'pointer',
                    display: {xs: 'block', sm: 'table-row'},
                    marginBottom: {xs: 2, sm: 0},
                    backgroundColor: 'white',
                    '&:hover': {
                        backgroundColor: '#f5f5f5',
                    },
                }}
            >
                <TableCell
                    align="center"
                    sx={{
                        display: {xs: 'flex', sm: 'table-cell'},
                        flexDirection: {xs: 'column', sm: 'row'},
                        justifyContent: {xs: 'space-between', sm: 'center'},
                        alignItems: {xs: 'flex-start', sm: 'center'},
                        fontSize: '15px',
                        backgroundColor: {xs: '#F0F0F0', sm: 'inherit'},
                        width: {xs: '100%', sm: '20%'},
                        padding: '16px',
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{display: {xs: 'block', sm: 'none'}, fontSize: '15px'}}
                    >
                        Αριθμός Ticket
                    </Typography>
                    <div style={{fontWeight: '600', fontSize: '15px'}}>{ticket.uuid}</div>
                </TableCell>
                <TableCell
                    align="center"
                    sx={{
                        display: {xs: 'flex', sm: 'table-cell'},
                        flexDirection: {xs: 'column', sm: 'row'},
                        justifyContent: {xs: 'space-between', sm: 'center'},
                        alignItems: {xs: 'flex-start', sm: 'center'},
                        fontSize: '15px',
                        width: {xs: '100%', sm: '20%'},
                        padding: '16px',
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{display: {xs: 'block', sm: 'none'}, fontSize: '15px'}}
                    >
                        Φορέας
                    </Typography>
                    {ticket.thematic?.carrier?.title || 'Δεν υπάρχει Θεματική'}
                </TableCell>
                <TableCell
                    align="center"
                    sx={{
                        display: {xs: 'flex', sm: 'table-cell'},
                        flexDirection: {xs: 'column', sm: 'row'},
                        justifyContent: {xs: 'space-between', sm: 'center'},
                        alignItems: {xs: 'flex-start', sm: 'center'},
                        fontSize: '15px',
                        width: {xs: '100%', sm: '20%'},
                        padding: '16px',
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{display: {xs: 'block', sm: 'none'}, fontSize: '15px'}}
                    >
                        Ημερομηνία Δημιουργίας
                    </Typography>
                    {new Date(ticket.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell
                    align="center"
                    sx={{
                        display: {xs: 'flex', sm: 'table-cell'},
                        flexDirection: {xs: 'column', sm: 'row'},
                        justifyContent: {xs: 'space-between', sm: 'center'},
                        alignItems: {xs: 'flex-start', sm: 'center'},
                        fontSize: '15px',
                        width: {xs: '100%', sm: '20%'},
                        padding: '16px',
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        sx={{display: {xs: 'block', sm: 'none'}, fontSize: '15px'}}
                    >
                        Κατάσταση
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: {xs: 'space-between', sm: 'center'},
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: ticket.ticketStatus.id === 4 ? 'green' : '#8B0000',
                                color: 'white',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                border: 'none',
                                display: 'inline-block',
                                fontSize: '15px',
                            }}
                        >
                            {ticket.ticketStatus.id === 4 ? 'Ανοιχτό' : 'Κλειστό'}
                        </Box>
                        {isMobile && (
                            <TableCell align="center" sx={{
                                display: {xs: 'table-cell', sm: 'none', border: "none"},
                                width: '20%'
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'relative'
                                }}>
                                    <IconButton size="small"
                                                onClick={() => handleRowClick(ticket)}
                                                sx={{ color: '#003476' }}
                                                aria-label={expandedRow === ticket.id ? "Collapse Row" : "Expand Row"}>
                                        {expandedRow === ticket.id ? <ExpandLess /> : <ExpandMore />}
                                    </IconButton>

                                    {loadingTicketId === ticket.id && (
                                        <CircularProgress size={18} sx={{
                                            position: 'absolute',
                                            right: -24
                                        }}
                                         aria-label="Loading"
                                        />
                                    )}
                                </Box>
                            </TableCell>
                        )}
                    </Box>
                </TableCell>
                <TableCell align="center"
                           sx={{display: {xs: 'none', sm: 'table-cell'}, width: '20%'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <IconButton size="small"  aria-label={expandedRow === ticket.id ? "Collapse Row" : "Expand Row"}>
                            {expandedRow === ticket.id ? <ExpandLess/> : <ExpandMore/>}
                        </IconButton>
                        {loadingTicketId === ticket.id && (
                            <CircularProgress size={18} sx={{ml: 1}}/>
                        )}
                    </Box>
                </TableCell>
            </TableRow>
            <TableRow sx={{borderBottom: {xs: '15px solid transparent', sm: 'none'}}}>
                <TableCell
                    colSpan={5}
                    sx={{
                        paddingBottom: 0,
                        paddingTop: 0,
                        backgroundColor: {
                            xs: 'none',
                            sm: '#F0F0F0',
                        },
                    }}
                >
                    <TicketDetails
                        ticket={ticket}
                        thematics={thematics}
                        ticketNotes={ticketNotes}
                        ticketFiles={ticketFiles}
                        thematicsLoading={thematicsLoading}
                        noteLoading={noteLoading}
                        expandedRow={expandedRow}
                    />
                </TableCell>
            </TableRow>
        </>
    );
};

export default TicketRow;