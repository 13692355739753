import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import BreadcrumbsList from 'app/main/KnowledgeBase/Breadcrumbs';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const MobileCarrierThematicsList = ({ carriers, selectedCarrier, selectedThematic, selectedThematics, currentThematics, onCarrierSelect, onThematicSelect, handleBreadcrumbClick }) => {
    return (
        <Box className="w-full  bg-gray-200 relative">
            <Box className="w-full bg-[#003476] text-white p-2">
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Επιλέξτε Φορέα
                </Typography>
                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                    <Select
                        labelId="carrier-select-label"
                        id="carrier-select"
                        value={selectedCarrier?.id || ''}
                        onChange={(e) => {
                            const carrier = carriers.find(c => c.id === e.target.value);
                            onCarrierSelect(carrier);
                        }}
                        sx={{
                            backgroundColor: 'white',
                            color: 'black',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black'
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'black'
                            },
                            '.MuiSvgIcon-root': {
                                color: 'black'
                            },
                            '.MuiSelect-select': {
                                color: 'black'
                            }
                        }}
                    >
                        {carriers?.map((carrier) => (
                            <MenuItem key={carrier?.id} value={carrier?.id}>
                                {carrier.title}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            {selectedCarrier && (
                <Box className="mt-4 p-2" sx={{ flexGrow: 1 }}>
                    <BreadcrumbsList handleBreadcrumbClick={handleBreadcrumbClick} selectedCarrier={selectedCarrier} selectedThematics={selectedThematics} />
                    <List>
                        {currentThematics?.map((thematic) => (
                            <ListItem
                                key={thematic.id}
                                button
                                onClick={() => onThematicSelect(thematic)}
                                sx={{
                                    mb: 2,
                                    backgroundColor: selectedThematic?.id === thematic.id ? '#003476' : 'inherit',
                                    color: selectedThematic?.id === thematic.id ? 'white' : '#262626',
                                    '&:hover': {
                                        backgroundColor: '#003476',
                                        color: 'white'
                                    }
                                }}
                            >
                                <ListItemText primary={thematic.title} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            )}
        </Box>
    );
};

export default MobileCarrierThematicsList;
