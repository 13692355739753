import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {jwtService} from "app/auth/jwtService";
import {useNavigate} from "react-router-dom";

const CarrierList = ({ carriers, onSelect , loading }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    // Sort carriers based on priority
    const sortedCarriers = carriers.slice().sort((a, b) => a.priority - b.priority);
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const isLoggedIn = jwtService.getUser();
    return (
        <>
            {isLoggedIn && (
                <Box className="flex items-center w-full " sx={{ justifyContent: 'space-between' }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#003476',
                            color: '#ffffff',
                            mb: 1,
                            ml: 1,
                            mt: 1,
                            boxShadow: 'none',
                            '&:hover': {
                                boxShadow: 3,
                            },
                        }}
                        startIcon={<ArrowCircleLeftIcon />}
                        onClick={() => navigate("/mypage")}
                    >
                        Πισω
                    </Button>
                </Box>
            )}
            <Box className={`carrier-list grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center mt-4 ${isMobile ? 'w-full' : isLargeScreen ? 'lg:w-1/2' : 'md:w-3/4'}`}>
                {loading ? (
                    Array.from({ length: 8 }).map((_, index) => (
                        <Box
                            key={index}
                            className="flex p-2 border rounded-md cursor-pointer transition-transform transform duration-200"
                            sx={{
                                backgroundColor: '#003476',
                                height: '150px',
                            }}
                        >
                            <Box className="flex flex-col justify-between ml-2 w-full">
                                <Box className="bg-gray-300 h-[10%] mt-4 w-[75%] rounded"></Box>
                                <Box className="bg-gray-300 h-[7%] mt-auto mb-4 w-[50%] rounded"></Box>
                            </Box>
                            <Box className="flex-shrink-0 w-[33%] flex items-center justify-center">
                                <Box className="bg-gray-300 h-[70%] w-[70%] rounded"></Box>
                            </Box>
                        </Box>
                    ))
                ) : (
                    sortedCarriers.map((carrier, index) => {
                        const isLastSingleCarrier = index === sortedCarriers.length - 1 && sortedCarriers.length % 2 === 1;
                        return (
                            <Box
                                key={carrier.id}
                                className={`flex p-2 border rounded-md cursor-pointer hover:shadow-2xl transition-transform transform hover:scale-95 duration-200  ${isLastSingleCarrier ? 'sm:col-span-2' : ''}`}
                                onClick={() => onSelect(carrier)}
                                sx={{
                                    backgroundColor: '#003476',
                                    minWidth: '100px',
                                    height: 'auto',
                                    maxWidth: isLastSingleCarrier ? '100%' : '30em',
                                    width: isLastSingleCarrier ? '100%' : 'auto',
                                }}
                            >
                                <Box className="flex flex-col justify-between ml-2 w-2/3">
                                    <Typography variant="h6" className="text-white break-words mt-4">
                                        {carrier.title}
                                    </Typography>
                                    <Typography variant="body2" className="text-white flex items-center mt-auto mb-4">
                                        Προβολή <ArrowForwardIosIcon fontSize="small" className="ml-1" />
                                    </Typography>
                                </Box>
                                <Box className="flex-shrink-0 w-1/3 flex items-center justify-center">
                                    <img
                                        src={`logo/1555/${carrier.id}.png`}
                                        alt={`${carrier.title} logo`}
                                        className="object-contain"
                                        style={{
                                            maxWidth: isLastSingleCarrier ? (isMobile ? '100px' : '150px') : '100%',
                                            maxHeight: isLastSingleCarrier ? (isMobile ? '100px' : '150px') : '100%',
                                        }}
                                    />
                                </Box>
                            </Box>
                        );
                    })
                )}
            </Box>
        </>
    );
};

export default CarrierList;
