import {jwtService} from "app/auth/jwtService";

export const encode = (data) => {
    try {
        return btoa(encodeURIComponent(data));
    } catch (error) {
        console.error("Failed to encode string:", error);
        jwtService.logout();
        return null;
    }
};

export const decode = (data) => {
    try {
        return decodeURIComponent(atob(data));
    } catch (error) {
        console.error("Failed to decode string:", error);
        jwtService.logout();
        return null;
    }
};