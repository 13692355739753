import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography, Grid, Button, Container, TextField} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {jwtService} from 'app/auth/jwtService';
import {useDispatch, useSelector} from 'react-redux';
import DynamicSelector from 'app/main/SignedIn/DynamicSelectorThematics';
import {createUserThunk, fetchSpecificUser, selectFetchedUser, updateUserThunk} from 'app/store/userSlice';
import {validatePhoneNumber} from './utils';
import BasicInfo from './BasicInfo';
import ContactInfo from './ContactInfo';
import {createTicket, uploadFile} from 'app/Api Calls/TicketApi';
import SubmissionIndicator from './SubmissionIndicator';
import TicketFileSelect from 'app/main/SignedIn/TicketForm/TicketFile';
import {clearSelections} from 'app/store/knowledgeBaseSlice';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import {showMessage} from "app/store/messageSlice";

const TicketForm = () => {
    const navigate = useNavigate();
    const user = jwtService.getUser();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [afm, setAfm] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [amka, setAmka] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [thematicError, setThematicError] = useState(false);
    const [geographyError, setGeographyError] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const [showSubmissionIndicator, setShowSubmissionIndicator] = useState(false);
    const [files, setFiles] = useState([]);
    const dispatch = useDispatch();
    const [submitClicked, setSubmitClicked] = useState(false);
    const {
        thematicMap,
        selectedThematic,
        selectedGeography,
        selectedThematics,
        selectedCarrier
    } = useSelector((state) => state.knowledgeBase);
    const fetchedUserData = useSelector(selectFetchedUser);
    const fetchedUser = fetchedUserData ? fetchedUserData.data : null;
    const fetchedStatus = fetchedUserData ? fetchedUserData.status : null;
    const [ticketDescription, setTicketDescription] = useState('');
    const [userFetchStatus, setUserFetchStatus] = useState(null);
    const topRef = useRef(null);
    const [fileError, setFileError] = useState('');

    useEffect(() => {
        const fetchUser = async () => {
            const response = await dispatch(fetchSpecificUser());
            setUserFetchStatus(response.payload.status);
            const userData = response.payload.data;
            if (userData) {
                setEmail(userData?.email || '');
                setMobile((userData?.mobile || ''));
                setAmka(userData?.amka || '');
                setPhone(userData?.phone || '');
            }
            setAfm(user?.afm || '');
            setFirstName(user?.firstName || '');
            setLastName(user?.lastName || '');
        };
        if (!fetchedUser) {
            fetchUser();
        } else {
            setUserFetchStatus(fetchedStatus || 200);
            setEmail(fetchedUser?.email || '');
            setMobile((fetchedUser?.mobile || ''));
            setAmka(fetchedUser?.amka || '');
            setPhone(fetchedUser?.phone || '');
            setAfm(user?.afm || '');
            setFirstName(user?.firstName || '');
            setLastName(user?.lastName || '');
        }
    }, []);

    useEffect(() => {
        if ((Object.keys(errors).length > 0 || thematicError || geographyError) && submitClicked) {
            topRef.current.scrollIntoView({behavior: 'smooth'});
            setSubmitClicked(false); // Reset submitClicked to false after scrolling
        }
    }, [errors, thematicError, geographyError, submitClicked]);

    const handleDescriptionChange = (e) => {
        const value = e.target.value;
        setTicketDescription(value);
        const descriptionWithoutSpaces = value.replace(/\s/g, '');
        if (!descriptionWithoutSpaces || descriptionWithoutSpaces.length < 11) {
            setErrors((prev) => ({...prev, description: 'Η περιγραφή πρέπει να είναι τουλάχιστον 10 χαρακτήρες '}));
        } else {
            setErrors((prev) => ({...prev, description: ''}));
        }
    };

    const handleFileErrors = (newFiles) => {
        const invalidFiles = newFiles.filter(file => !['application/pdf', 'image/jpeg', 'image/jpg'].includes(file.file.type));
        if (invalidFiles.length > 0) {
            setFileError('Επιτρέπονται μόνο pdf, jpeg, jpg αρχεία');
            return true;
        } else if (newFiles.length > 5) {
            setFileError('Μπορείτε να ανεβάσετε μέχρι 5 αρχεία');
            return true;
        } else if (selectedCarrier?.id === 12) {
            const pdfFiles = newFiles.filter(file => file.file.type === 'application/pdf');
            if (pdfFiles.length !== 1) {
                setFileError('Θα πρέπει να ανεβάσετε Υπεύθυνη Δήλωση υπογεγραμμένη μέσω της ενιαίας ψηφιακής πύλης της Δημόσιας Διοίκησης (<a href="https://www.gov.gr/" target="_blank" rel="noopener noreferrer">www.gov.gr</a>)');
                return true;
            } else {
                setFileError('');
                return false;
            }
        } else {
            setFileError('');
            return false;
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);
        setShowSubmissionIndicator(true);
        setSubmitClicked(true);
        const newErrors = {};
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneError = phone ? validatePhoneNumber(phone, 'landline') : '';
        const mobileError = validatePhoneNumber(mobile, 'mobile');

        if (!email || !emailPattern.test(email)) {
            newErrors.email = 'Παρακαλώ εισάγετε ένα έγκυρο email';
        }
        if (phoneError) {
            newErrors.phone = phoneError;
        }
        if (mobileError) {
            newErrors.mobile = mobileError;
        }

        if (!amka || amka.length !== 11) {
            newErrors.amka = 'Παρακαλώ εισάγετε ένα έγκυρο ΑΜΚΑ (11 ψηφία)';
        }

        const descriptionWithoutSpaces = ticketDescription.replace(/\s/g, '');
        if (!descriptionWithoutSpaces || descriptionWithoutSpaces.length < 10) {
            newErrors.description = 'Η περιγραφή πρέπει να είναι τουλάχιστον 10 χαρακτήρες ';
        }

        const geographyError = selectedCarrier?.id === 2 && !selectedGeography;
        setGeographyError(geographyError);

        const thematicError = !(
            selectedThematic &&
            Array.isArray(thematicMap.thematics[selectedThematic.id]) &&
            thematicMap.thematics[selectedThematic.id].length === 0 &&
            selectedThematics &&
            selectedThematics.length > 0 &&
            thematicMap.thematics[selectedThematics[selectedThematics.length - 1].id].length === 0
        );

        setThematicError(thematicError);

        const hasFileErrors = handleFileErrors(files);
        if (Object.keys(newErrors).length > 0 || thematicError || geographyError || hasFileErrors) {
            setErrors(newErrors);
            setIsSubmitting(false);
            setShowSubmissionIndicator(false);
            return;
        }

        let userNeedsUpdate = false;
        if (!fetchedUser || userFetchStatus !== 200) {
            userNeedsUpdate = true;
        } else {
            // Compare form values with fetched user data to determine if update is needed
            if (email !== fetchedUser?.email) userNeedsUpdate = true;
            if (mobile !== fetchedUser?.mobile) userNeedsUpdate = true;
            if (amka !== fetchedUser?.amka) userNeedsUpdate = true;
            if (phone !== fetchedUser?.phone) userNeedsUpdate = true;
        }

        if (userNeedsUpdate) {
            if (!fetchedUser || userFetchStatus !== 200) {
                // Create user if fetchedUser does not exist or fetch status is not 200
                const newUser = {
                    email,
                    mobile,
                    amka,
                    phone,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    afm: user.afm
                };
                const createUserResponse = await dispatch(createUserThunk(newUser));
                if (!createUserResponse.payload || createUserResponse.payload.status !== 200) {
                    console.error('Failed to create user:', createUserResponse.error);
                    setIsSubmitting(false);
                    setShowSubmissionIndicator(false);
                    return;
                } else {
                    // Fetch user after successful creation
                    await dispatch(fetchSpecificUser());
                }
            } else {
                // Update user if there are differences
                const updatedUser = {
                    ...fetchedUser,
                    email,
                    mobile,
                    amka,
                    phone,
                };
                const updateUserResponse = await dispatch(updateUserThunk(updatedUser));
                if (!updateUserResponse.payload || updateUserResponse.payload.status !== 200) {
                    console.error('Failed to update user:', updateUserResponse.error);
                    setIsSubmitting(false);
                    setShowSubmissionIndicator(false);
                    return;
                } else {
                    // Fetch user after successful update
                    await dispatch(fetchSpecificUser());
                }
            }
        }

        const newTicket = {
            description: ticketDescription,
            thematicId: selectedThematic.id,
            geographyId: selectedGeography?.id,
        };

        const response = await createTicket(newTicket);
        if (response) {
            // Upload files if any
            // Upload files if any
            if (files.length > 0) {
                try {
                    const uploadPromises = files.map(file => uploadFile(response.id, file.file, 1));
                    await Promise.all(uploadPromises);
                } catch (error) {
                    console.error('Failed to upload files:', error);
                    dispatch(showMessage({
                        message: 'Αποτυχία ανεβάσματος αρχείου',
                        autoHideDuration: 8000,
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "center",
                        },
                        variant: "error",
                    }));
                    setIsSubmitting(false);
                    setShowSubmissionIndicator(false);
                    return;
                }
            }

            setSubmissionSuccess(true);
            // Reset form and states
            dispatch(clearSelections());
            setTicketDescription('');
            setEmail('');
            setMobile('');
            setAmka('');
            setPhone('');
            setFiles([]);
            setErrors({});
            setThematicError(false);
            setGeographyError(false);
        } else {
            console.error('Failed to create ticket');
            dispatch(showMessage({
                message: 'Η δημιουργία του ticket απέτυχε',
                autoHideDuration: 8000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            }));
            setIsSubmitting(false);
            setShowSubmissionIndicator(false);
        }
    };

    const handleClose = () => {
        setShowSubmissionIndicator(false);
        setIsSubmitting(false);
        if (submissionSuccess) {
            navigate('/mytickets');
        }
    };

    return (
        <Box ref={topRef} sx={{backgroundColor: "#ffffff", position: 'relative'}}>
            <Container sx={{backgroundColor: "#ffffff"}}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#003476',
                        color: '#ffffff',
                        mt: 4,
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 3,
                        },
                    }}
                    startIcon={<ArrowCircleLeftIcon/>}
                    onClick={() => navigate("/mypage")}
                >
                    Πισω
                </Button>
                <Grid container spacing={3} sx={{mt: 2, mb: 2}}>
                    <BasicInfo afm={afm} firstName={firstName} lastName={lastName} amka={amka} setAmka={setAmka}
                               errors={errors} setErrors={setErrors}/>
                    <ContactInfo
                        email={email}
                        setEmail={setEmail}
                        phone={phone}
                        setPhone={setPhone}
                        mobile={mobile}
                        setMobile={setMobile}
                        errors={errors}
                        setErrors={setErrors}
                    />
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            Θεματικές Κατηγορίες
                        </Typography>
                        <DynamicSelector thematicError={thematicError} geographyError={geographyError}
                                         setThematicError={setThematicError} setGeographyError={setGeographyError}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={{mb: 2}} variant="h5">Περιγραφή</Typography>
                        <TextField
                            fullWidth
                            multiline
                            rows={6}
                            sx={{backgroundColor: "#f0f0f0"}}
                            variant="outlined"
                            value={ticketDescription}
                            onChange={handleDescriptionChange}
                            error={!!errors.description}
                            helperText={errors.description ? errors.description : ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TicketFileSelect files={files} setFiles={setFiles} selectedCarrierId={selectedCarrier?.id}
                                          fileError={fileError} setFileError={setFileError}/>
                    </Grid>
                    <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button variant="contained" color="primary" sx={{mt: 2, mb: 2}} onClick={handleSubmit}
                                disabled={isSubmitting}>
                            Υποβολή
                        </Button>
                    </Grid>
                </Grid>
            </Container>
            {showSubmissionIndicator && <SubmissionIndicator success={submissionSuccess} onClose={handleClose}/>}
        </Box>
    );
};

export default TicketForm;
