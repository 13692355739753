import React from 'react';
import { Grid, Box, FormControl, Typography } from '@mui/material';
import {CustomTextField, validatePhoneNumber} from './utils';

const ContactInfo = ({ email, setEmail, phone, setPhone, mobile, setMobile, errors, setErrors }) => {
    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        if (!value || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            setErrors((prev) => ({ ...prev, email: 'Παρακαλώ εισάγετε ένα έγκυρο email' }));
        } else {
            setErrors((prev) => ({ ...prev, email: '' }));
        }
    };

    const handlePhoneChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setPhone(value);
            const phoneError = value ? validatePhoneNumber(value, 'landline') : '';
            setErrors((prev) => ({ ...prev, phone: phoneError }));
        }
    };

    const handleMobileChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setMobile(value);
            const mobileError = validatePhoneNumber(value, 'mobile');
            setErrors((prev) => ({ ...prev, mobile: mobileError }));
        }
    };

    return (
        <Grid item xs={12} sm={6}>
            <Typography variant="h5">
                Στοιχεία Επικοινωνίας
            </Typography>
            <CustomTextField
                fullWidth
                label="(*) EMAIL"
                variant="outlined"
                value={email || ''}
                onChange={handleEmailChange}
                error={!!errors.email}
                helperText={errors.email}
            />
            <Box>
                <FormControl fullWidth variant="outlined">
                    <CustomTextField
                        fullWidth
                        label="ΤΗΛΕΦΩΝΟ"
                        variant="outlined"
                        value={phone || ''}
                        onChange={handlePhoneChange}
                        error={!!errors.phone}
                        helperText={errors.phone}
                    />
                </FormControl>
            </Box>
            <Box>
                <FormControl fullWidth variant="outlined">
                    <CustomTextField
                        fullWidth
                        label="(*) ΚΙΝΗΤΟ"
                        variant="outlined"
                        value={mobile || ''}
                        onChange={handleMobileChange}
                        error={!!errors.mobile}
                        helperText={errors.mobile}
                    />
                </FormControl>
            </Box>
        </Grid>
    );
};

export default ContactInfo;
