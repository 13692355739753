import { Box, Collapse, Divider, Typography } from "@mui/material";
import TicketFiles from "app/main/SignedIn/TicketFiles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import EastIcon from "@mui/icons-material/East";

const TicketDetails = ({
                           ticket,
                           thematics,
                           ticketNotes,
                           ticketFiles,
                           thematicsLoading,
                           noteLoading,
                           expandedRow
                       }) => (
    <Collapse
        in={expandedRow === ticket.id && !thematicsLoading && !noteLoading}
        timeout="auto"
        unmountOnExit
    >
        <Box sx={{ pb: 2, pr: 2, pt: 2, pl: { xs: 0, sm: 2, md: 6, lg: 8 }, overflow: 'hidden' }}>
            {thematics[ticket.id] && thematics[ticket.id].length > 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        flexWrap: 'wrap',
                        overflow: 'hidden',
                        wordBreak: 'break-word'
                    }}
                >
                    <Typography
                        component="div"
                        variant="body2"
                        sx={{ fontSize: '15px' }}
                    >
                        {thematics[ticket.id].map((thematic, index) => (
                            <span
                                key={index}
                                style={{
                                    color: '#003476',
                                    fontWeight: 600,
                                    display: 'inline',
                                }}
                            >
                                {thematic.title}
                                {index < thematics[ticket.id].length - 1 && ' / '}
                            </span>
                        ))}
                    </Typography>
                </Box>
            )}

            {ticket.ticketStatus.id === 5 && ticketNotes[ticket.id] && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        mb: 2,
                        overflow: 'hidden',
                        wordBreak: 'break-word'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#003476',
                            fontWeight: 600,
                            fontSize: '17px',
                        }}
                    >
                        <EastIcon sx={{ marginRight: 1 }} />
                        <span>
                            Περιγραφή
                        </span>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <Typography component="div" variant="body2">
                            <span
                                style={{
                                    display: 'inline',
                                    fontSize: '16px',
                                }}
                            >
                                {ticket.description}
                            </span>
                        </Typography>
                    </Box>
                    <TicketFiles
                        files={ticketFiles[ticket.id]}
                        filterType="beneficiary"
                        title="Ανεβασμένα Αρχεία απο Δικαιούχο"
                    />
                    <Divider sx={{ borderBottomWidth: 2, mb: 2 }} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mb: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',

                            }}
                        >
                            <Typography component="div" variant="body2">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        color: '#003476',
                                        fontWeight: 600,
                                        fontSize: '17px',
                                    }}
                                >
                                    <KeyboardBackspaceIcon sx={{ marginRight: 1 }} />
                                    <span>
                                        Απάντηση Ticket
                                    </span>
                                </Box>
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2
                            }}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: ticketNotes[ticket.id].note,
                                }}
                            />
                        </Box>
                        <TicketFiles
                            files={ticketFiles[ticket.id]}
                            filterType="representative"
                            title="Ανεβασμένα αρχεία από Εκπρόσωπο Φορέα"
                        />
                    </Box>
                </Box>
            )}

            {ticket.ticketStatus.id !== 5 && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        mb: 0.5,
                        overflow: 'hidden',
                        wordBreak: 'break-word',

                    }}
                >
                    <Typography
                        component="div"
                        variant="body2"
                        sx={{ fontSize: '15px', mb: 2 }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: '#003476',
                                fontWeight: 600,
                                fontSize: '17px',
                            }}
                        >
                            <EastIcon sx={{ marginRight: 1 }} />
                            <span>
                                Περιγραφή
                            </span>
                        </Box>
                        <span
                            style={{
                                display: 'inline',
                                fontSize: '16px',
                            }}
                        >
                            {ticket.description}
                        </span>
                    </Typography>
                    {ticketFiles[ticket.id] && ticketFiles[ticket.id].some(file => file.uploadedByBeneficiaryId) && (
                        <>
                            <TicketFiles
                                files={ticketFiles[ticket.id].filter(file => file.uploadedByBeneficiaryId)}
                                filterType="beneficiary"
                                title="Ανεβασμένα Αρχεία απο Δικαιούχο"
                            />
                        </>
                    )}
                    <Divider flexItem sx={{ borderBottomWidth: 2, mb: 2 }} />
                    <Typography
                        component="div"
                        variant="body2"
                        sx={{ fontSize: '16px' }}
                    >
                        Το αίτημα είναι σε εκκρεμότητα
                    </Typography>
                </Box>
            )}
        </Box>
    </Collapse>
);

export default TicketDetails;
