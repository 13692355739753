import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import persistedReducer from './rootReducer';

const isDevelopment = process.env.NODE_ENV === 'development';

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Disable the serializable check for redux-persist
        }),
    devTools: isDevelopment, // Enable Redux DevTools extension only in development
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
