import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import messageSlice, {initialMessageState} from './messageSlice';
import knowledgeBaseSlice, { knowledgebaseInitialState } from 'app/store/knowledgeBaseSlice';
import userSlice, { logoutUser, userInitialState } from 'app/store/userSlice';
import ticketSlice, {ticketsInitialState} from "app/store/ticketSlice";

// Get the initial states of each slice
const initialKnowledgeBaseState = knowledgebaseInitialState;
const initialTicketsState = ticketsInitialState;
const initialUserState = userInitialState;

const appReducer = combineReducers({
    user: userSlice,
    message: messageSlice,
    knowledgeBase: knowledgeBaseSlice,
    tickets: ticketSlice,
});

// Persist config for redux-persist
const persistConfig = {
    key: 'store',
    storage: sessionStorage,
    whitelist: ['user', 'knowledgeBase', 'tickets']
};

const rootReducer = (state, action) => {
    if (action.type === logoutUser.type) {
        const { carriers, thematicMap } = state.knowledgeBase;
        state = {
            user: initialUserState,
            message: initialMessageState,
            knowledgeBase: {
                ...initialKnowledgeBaseState,
                carriers,
                thematicMap
            },
            tickets: initialTicketsState,
        };
    }
    return appReducer(state, action);
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
