import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, ListItemIcon, Paper, Typography, Button } from '@mui/material';
import PushPinIcon from "@mui/icons-material/PushPin";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AnnouncementModal from "app/main/SignedIn/Announcments/AnnouncmentModal";

function formatDate(dateString) {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function determineDateColor(validUntil) {
    if (!validUntil) return 'gray';
    const now = new Date();
    const validDate = new Date(validUntil);
    return validDate >= now ? 'green' : 'red';
}

function AnnouncementsDisplay({ announcements, selectedCarrier, showDetailedHeader = false, closeModal }) {
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOpen = (announcement) => {
        setSelectedAnnouncement(announcement);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedAnnouncement(null);
    };

    const filteredAndSortedAnnouncements = announcements.sort((a, b) => b.sticky - a.sticky);

    return (
        <Box sx={{ width: '100%', overflowY: 'auto', bgcolor: '#ffffff', p: 2, boxSizing: 'border-box' }}>
            {!showDetailedHeader ? (
                <Typography variant="h6" sx={{ ml: 2, mt: 1, mb: 2 }}>
                    Ανακοινώσεις Φορέα {selectedCarrier?.label ? ` - ${selectedCarrier?.label}` : ''}
                </Typography>
            ) : (
                <Box sx={{
                    position: 'sticky', top: 0, zIndex: 1,
                    bgcolor: 'background.paper', px: 4, py: 2,
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ flexGrow: 1, textAlign: 'center' }}>
                        Ανακοινώσεις του Φορέα {selectedCarrier?.label}
                    </Typography>
                    <Button variant="contained" sx={{ bgcolor: "#003476 !important", color: "#fff" }} onClick={closeModal}>
                        Κλείσιμο
                    </Button>
                </Box>
            )}
            {filteredAndSortedAnnouncements.length > 0 ? (
                <List sx={{ width: "100%", p: 0 }}>
                    {filteredAndSortedAnnouncements.map((announcement, index) => (
                        <Paper key={index} elevation={2} sx={{
                            my: 1,
                            bgcolor: '#fafafa',
                            cursor: 'pointer',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1), 0 -2px 4px rgba(0,0,0,0.1)',
                            width: '100%',
                            '&:hover': {
                                bgcolor: '#e0e0e0'
                            }
                        }}>
                            <ListItem button onClick={() => handleOpen(announcement)} sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: 2,
                                mb: 2,
                                width: '100%',
                                height: '100%',
                                flexDirection: { xs: 'column', sm: 'row' }
                            }}>
                                {announcement.sticky && (
                                    <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>
                                        <PushPinIcon sx={{ color: 'red', verticalAlign: 'middle' }} />
                                    </ListItemIcon>
                                )}
                                <ListItemText
                                    primary={<Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{announcement.title}</Typography>}
                                    secondary={
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="body2" color="textSecondary">
                                                Δημοσιεύθηκε: {formatDate(announcement.createdAt)}
                                            </Typography>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                <EventAvailableIcon sx={{ fontSize: 20, color: determineDateColor(announcement.validUntil) }} />
                                                {announcement.validUntil ? (
                                                    <Typography variant="body2" sx={{ ml: 0.5, color: determineDateColor(announcement.validUntil) }}>
                                                        Ενεργό έως: {formatDate(announcement.validUntil)}
                                                    </Typography>
                                                ) : (
                                                    <Typography variant="body2" sx={{ ml: 0.5, color: 'gray' }}>
                                                        Δεν υπάρχει ημερομηνία
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        </Paper>
                    ))}
                </List>
            ) : (
                <Typography sx={{ m: 2 }}>Δεν υπάρχουν Ανακοινώσεις για τον Φορέα</Typography>
            )}
            <AnnouncementModal open={open} handleClose={handleClose} announcement={selectedAnnouncement} />
        </Box>
    );
}

export default AnnouncementsDisplay;
