import { axiosApi } from "app/configs/axiosConfig";

// Function to create a new ticket
export async function createTicket(ticket) {
    const defaultValues = {
        ticketFlg: 0,
        priorityFlg: 0,
        thematicId: 0,
        ticketStatusId: 4,
        callerId: null,
        representativeFlg: 0,
        followUpFlg: 0,
        applicationFlg: 0,
        title: null,
        shortDescription: null,
        ticketFlagCollection: [],
    };

    const ticketData = { ...defaultValues, ...ticket };

    try {
        const response = await axiosApi.post(`/webportal/ticket`, ticketData);
        return response.data;
    } catch (error) {
        console.error('Failed to create ticket:', error);
        return null;
    }
}

// ticketApi.js

export const fetchTickets = async (page, size) => {
    try {
        const response = await axiosApi.post(`/webportal/ticket/search/${page}/${size}`, {
            fromDate: null,
            toDate: null,
            ticketStatusId: null,
            uuid: null ,
            orderDto: [
                {
                    orderByColumn: "id",
                    ordrerOrientation: "desc"
                }
            ]
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching tickets:", error);
        throw error;
    }
};



export const uploadFile = async (ticketId, file, isPublished) => {
    const formData = new FormData();
    formData.append('filedata', file);

    const response = await axiosApi.post(`/webportal/file/ticket/${ticketId}/${isPublished}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};


export const downloadFile = async (fileId) => {
    const response = await axiosApi.get(`/webportal/file/download/${fileId}`);
    return response.data.url;
};

// Function to fetch the latest note for a ticket
export const fetchLatestNoteApi = async (ticketId) => {
    try {
        const response = await axiosApi.get(`/webportal/ticket/ticketLatestNote/${ticketId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching the latest note for ticket ${ticketId}:`, error);
        throw error;
    }
};

export const fetchTicketFiles = async (ticketId) => {
    try {
        const response = await axiosApi.get(`/webportal/file/ticket/${ticketId}`);
        return response.data;
    } catch (error) {
        console.error(`Error fetching files for ticket ${ticketId}:`, error);
        throw error;
    }
};


