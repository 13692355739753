import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import BreadcrumbsList from 'app/main/KnowledgeBase/Breadcrumbs';
import { fetchThematicsByParentId, selectThematic} from 'app/store/knowledgeBaseSlice';
import MobileCarrierThematicsList from "app/main/KnowledgeBase/MobileViews/MobileCarrierThematicsList";

const CarrierThematicsList = ({onCarrierSelect, onThematicSelect, handleBack, handleBreadcrumbClick}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isBelow1100px = useMediaQuery('(max-width:1100px)');
    const {
        carriers,
        selectedCarrier,
        selectedThematic,
        selectedThematics,
        thematicMap
    } = useSelector((state) => state.knowledgeBase);

    const buttonMargin = 16;
    const typographyHeight = 45;
    const typographyMargin = selectedCarrier ? 8 : 16;
    const availableHeight = `calc(75vh - ${buttonMargin + typographyHeight + typographyMargin}px)`;
    const carrierHeight = `calc((${availableHeight} - ${(carriers.length - 1) * 8}px) / ${carriers.length})`;

    useEffect(() => {
            if (selectedCarrier?.id === 12 && selectedThematics.length === 0) {
                dispatch(selectThematic({id: 10010, title: 'Auto Selected Thematic'}));

                if (!thematicMap?.thematics[10010]) {
                    dispatch(fetchThematicsByParentId(10010));
                }
            }

    }, [selectedCarrier, dispatch]);

    const getCurrentThematics = () => {
        if (selectedCarrier?.id === 12 && selectedThematics.length === 0) {
            return thematicMap.thematics[10010] || [];
        }

        if (selectedThematics.length === 0) {
            return thematicMap.carriers[selectedCarrier?.id] || [];
        } else {
            const currentThematic = selectedThematics[selectedThematics.length - 1];
            const children = thematicMap.thematics[currentThematic.id] || [];
            if (children.length === 0) {
                return thematicMap.thematics[selectedThematics[selectedThematics.length - 2]?.id] || [];
            }
            return children;
        }
    };

    const currentThematics = getCurrentThematics();

    if (isMobile || isBelow1100px) {
        return (
            <MobileCarrierThematicsList
                carriers={carriers}
                selectedCarrier={selectedCarrier}
                selectedThematic={selectedThematic}
                selectedThematics={selectedThematics}
                currentThematics={currentThematics}
                onCarrierSelect={onCarrierSelect}
                onThematicSelect={onThematicSelect}
                handleBreadcrumbClick={handleBreadcrumbClick}
            />
        );
    }

    return (
        <Box className="flex w-full h-[75vh] bg-gray-200 relative">
            <Box className="w-1/3 bg-[#003476] text-white flex flex-col items-start p-2">
                <Typography variant="h5" sx={{mb: 1, ml: 2, mt: selectedCarrier ? 0 : 2}}>
                    Επιλέξτε Φορέα
                </Typography>
                <Box sx={{ width: '100%', overflow: 'auto' }}>
                    {carriers?.map((carrier) => (
                        <Box
                            key={carrier?.id}
                            className={`mb-1 cursor-pointer p-2 ${carrier?.id === selectedCarrier?.id ? 'bg-[#0056a3] text-white' : 'hover:bg-[#0056a3] text-white'}`}
                            onClick={() => onCarrierSelect(carrier)}
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                maxHeight: '100%',
                                whiteSpace: isBelow1100px ? 'nowrap' : 'normal',
                                overflow: isBelow1100px ? 'hidden' : 'visible',
                                textOverflow: isBelow1100px ? 'ellipsis' : 'clip',
                            }}
                        >
                            <Typography variant="h6" sx={{ ml: 2, fontSize: "15px"
                            }}>
                                {carrier.title}
                            </Typography>
                        </Box>
                    ))}
                </Box>


            </Box>
            <Box className="w-2/3 p-4 ml-2" sx={{overflowY: 'auto'}}>
                {!isMobile && selectedCarrier && (
                    <BreadcrumbsList handleBreadcrumbClick={handleBreadcrumbClick} selectedCarrier={selectedCarrier}
                                     selectedThematics={selectedThematics}/>
                )}
                <Box className="thematic-list">
                    {currentThematics?.map((thematic) => (
                        <Box
                            key={thematic.id}
                            className={`p-1 mb-2 cursor-pointer ${selectedThematic?.id === thematic.id ? 'bg-[#003476] text-white' : 'text-[#262626] hover:bg-[#003476] hover:text-white'}`}
                            onClick={() => {
                                onThematicSelect(thematic);
                            }}
                        >
                            <Typography variant="h6">
                                {thematic.title}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default CarrierThematicsList;
