import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CarrierThematicsList from 'app/main/KnowledgeBase/CarrierThematicsList';
import QnaList from 'app/main/KnowledgeBase/QnaList';
import {
    fetchQnaSearchResults,
    fetchThematicsByParentId,
    selectCarrier,
    selectThematic,
    setPage,
    popThematicStack,
    clearSelections,
    fetchThematicsByCarrierId,
    fetchCarriers,
    clearQnas,
    navigateToThematicLevel
} from 'app/store/knowledgeBaseSlice';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import LinearProgress from "@mui/material/LinearProgress";
import BreadcrumbsList from "app/main/KnowledgeBase/Breadcrumbs";
import Fade from '@mui/material/Fade';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

function CarrierThematicsPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const {
        qnaLoading,
        selectedCarrier,
        selectedThematic,
        thematicMap,
        selectedThematics,
        thematicsLoading
    } = useSelector((state) => state.knowledgeBase);


    useEffect(() => {
        if (!selectedCarrier) {
            navigate('/main');
        }
    }, [dispatch, selectedCarrier, navigate]);

    const handleThematicSelect = async (thematic) => {
        let children = thematicMap?.thematics[thematic.id];
        if (!children) {
            const result = await dispatch(fetchThematicsByParentId(thematic.id)).unwrap();
            children = result?.thematics;
        }

        if (selectedThematics?.length > 0 && thematicMap?.thematics[selectedThematics[selectedThematics.length - 1].id]?.length === 0) {
            dispatch(popThematicStack());
        }

        dispatch(selectThematic(thematic));

        if (children.length === 0) {
            dispatch(clearQnas());
            const qnaSearchRequestBody = {
                title: null,
                question: null,
                answer: null,
                thematicId: thematic.id,
                isPublished: true
            };
            dispatch(fetchQnaSearchResults({page: 0, size: 10, qnaSearchRequestBody}));
        }
    };

    const handleBack = () => {
        dispatch(popThematicStack());
    };

    const handlePageChange = (event, value) => {
        dispatch(setPage(value));
        const qnaSearchRequestBody = {
            title: null,
            question: null,
            answer: null,
            thematicId: selectedThematic.id,
            isPublished: true
        };
        dispatch(fetchQnaSearchResults({page: value - 1, size: 10, qnaSearchRequestBody}));
    };

    const handleBreadcrumbClick = async (index) => {
        if (index === 0) {
            dispatch(navigateToThematicLevel({index}));
        } else {
            const thematic = selectedThematics[index - 1];
            if (!thematicMap.thematics[thematic.id]) {
                await dispatch(fetchThematicsByParentId(thematic.id));
            }
            dispatch(navigateToThematicLevel({index}));
        }
    };

    const handleCarrierSelect = (carrier) => {
        dispatch(selectCarrier(carrier));
        if (!thematicMap.carriers[carrier.id]) {
            dispatch(fetchThematicsByCarrierId(carrier.id));
        }
    };

    return (
        <Box className="App container mx-auto p-4 flex flex-col items-center mt-8">
            {selectedCarrier && (
                <Box className="flex items-center w-full mb-4 mt-4" sx={{justifyContent: 'space-between'}}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#003476',
                            color: '#ffffff',
                            mb: 1,
                            ml: 1,
                            mt: 1,
                            boxShadow: 'none',
                            '&:hover': {
                                boxShadow: 3,
                            },
                        }}
                        startIcon={<ArrowCircleLeftIcon/>}
                        onClick={handleBack}
                    >
                        Πισω
                    </Button>
                    <Typography
                        variant="h6"
                        sx={{
                            textAlign: 'center',
                            flexGrow: 1,
                            mx: 2,
                            color: '#003476',
                            fontWeight: 500
                        }}
                    >
                        {selectedCarrier?.title}
                    </Typography>
                </Box>
            )}

            {!selectedThematic || thematicsLoading || thematicMap.thematics[selectedThematic?.id]?.length ? (
                <Fade in={!thematicsLoading} timeout={500}>
                    <Box sx={{width: '100%', maxWidth: '80vw'}}>
                        <CarrierThematicsList
                            onCarrierSelect={handleCarrierSelect}
                            onThematicSelect={handleThematicSelect}
                            handleBreadcrumbClick={handleBreadcrumbClick}
                        />
                    </Box>
                </Fade>
            ) : (
                <Fade in={!qnaLoading} timeout={500}>
                    <div className={"w-full"}>
                        {selectedCarrier && (
                            <BreadcrumbsList handleBreadcrumbClick={handleBreadcrumbClick}
                                             selectedCarrier={selectedCarrier}
                                             selectedThematics={selectedThematics} centered={true}/>
                        )}
                        <QnaList
                            handlePageChange={handlePageChange}
                        />
                    </div>
                </Fade>
            )}
            {qnaLoading && (
                <Box sx={{width: '100%', position: 'fixed', top: 0}}>
                    <LinearProgress/>
                </Box>
            )}
        </Box>
    );
}

export default CarrierThematicsPage;
