import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, Select, MenuItem, FormControl, Grid, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CarrierList from './CarrierList';
import AnnouncementsDisplay from "app/main/SignedIn/Announcments/AnnouncmentsDisplay";
import { getAnnouncementsByCarrierId } from "app/Api Calls/AnnouncementsCalls";
import { fetchCarriers } from "app/store/knowledgeBaseSlice";
import { jwtService } from "app/auth/jwtService";
import { useNavigate } from "react-router-dom";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

function AnnouncementsPage() {
    const dispatch = useDispatch();
    const carriers = useSelector((state) => state.knowledgeBase.carriersWith1555);
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [announcements, setAnnouncements] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMobile = useMediaQuery('(max-width:960px)');
    const isSmallHeight = useMediaQuery('(max-height:600px)');
    const user = jwtService.getUser();
    const token = jwtService.getAccessToken();
    const isSignedIn = user && user.id && token;
    const navigate = useNavigate();

    useEffect(() => {
        if (carriers.length === 0) {
            dispatch(fetchCarriers());
        }
    }, [dispatch, carriers?.length]);

    useEffect(() => {
        if (selectedCarrier) {
            setLoading(true);
            getAnnouncementsByCarrierId(selectedCarrier.id)
                .then(response => {
                    const publishedAnnouncements = response.filter(announcement => announcement.isPublished);
                    setAnnouncements(publishedAnnouncements);
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Failed to fetch announcements:', error);
                    setLoading(false);
                });
        }
    }, [selectedCarrier]);

    const handleCarrierSelect = (carrier) => {
        setSelectedCarrier(carrier);
    };

    const handleBackClick = () => {
        if (isSignedIn) {
            navigate("/mypage");
        } else {
            navigate("/main");
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: isSmallHeight ? 'auto' : '90vh',
            minHeight: isSmallHeight ? 'auto' : '90vh',
            p: { xs: 2, sm: 4 },
            mt: { xs: "64px", sm: "80px" },
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#003476',
                        color: '#ffffff',
                        mb: 1,
                        ml: 1,
                        mt: 1,
                        boxShadow: 'none',
                        '&:hover': {
                            boxShadow: 3,
                        },
                    }}
                    startIcon={<ArrowCircleLeftIcon />}
                    onClick={handleBackClick}
                >
                    Πισω
                </Button>
            </Box>
            <Grid container spacing={0} sx={{ flex: 1, overflowY: isSmallHeight ? 'unset' : 'auto', minHieght: isSmallHeight ? 'auto' : '100vh' }}>
                {isMobile ? (
                    <Grid item xs={12} sx={{ mb: 4 }}>
                        <Box sx={{ position: 'sticky', top: '0', zIndex: 1, bgcolor: '#003476', color: 'white', p: 2 }}>
                            <Typography variant="h6" sx={{ mb: 1 }}>
                                Επιλέξτε Φορέα
                            </Typography>
                            <FormControl fullWidth variant="outlined">
                                <Select
                                    id="carrier-select"
                                    value={selectedCarrier?.id || ''}
                                    onChange={(e) => handleCarrierSelect(carriers.find(carrier => carrier.id === e.target.value))}
                                    displayEmpty
                                    sx={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black'
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: 'black'
                                        },
                                        '.MuiSvgIcon-root': {
                                            color: 'black'
                                        },
                                        '.MuiSelect-select': {
                                            color: 'black'
                                        }
                                    }}
                                >
                                    {carriers?.map((carrier) => (
                                        <MenuItem key={carrier?.id} value={carrier?.id}>
                                            {carrier.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box sx={{ flexGrow: 1, overflowY: isSmallHeight ? 'unset' : 'auto', bgcolor: '#ffffff', p: 2, height: '100%' }}>
                            {!selectedCarrier && (
                                <Box>
                                    <Typography variant="subtitle1">Επιλέξτε Φορέα</Typography>
                                </Box>
                            )}
                            {!loading && selectedCarrier && (
                                <AnnouncementsDisplay announcements={announcements} selectedCarrier={selectedCarrier} />
                            )}
                        </Box>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={3} sx={{ height: '100%' }}>
                            <CarrierList
                                carriers={carriers}
                                selectedCarrierId={selectedCarrier?.id}
                                onCarrierSelect={handleCarrierSelect}
                                sx={{ height: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={9} sx={{ height: '100%', overflowY: 'auto', bgcolor: '#ffffff', p: 2 }}>
                            {!selectedCarrier && (
                                <Box>
                                    <Typography variant="h6">Ανακοινώσεις</Typography>
                                    <Typography variant="subtitle1">Επιλέξτε Φορέα</Typography>
                                </Box>
                            )}
                            {!loading && selectedCarrier && (
                                <AnnouncementsDisplay announcements={announcements} selectedCarrier={selectedCarrier} />
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}

export default AnnouncementsPage;
