import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import React from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const BreadcrumbsList = ({ handleBreadcrumbClick, selectedCarrier, selectedThematics, thematicMap, centered }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const isLastThematicWithoutChildren = (thematicId) => {
        return !thematicMap?.thematics[thematicId] || thematicMap?.thematics[thematicId].length === 0;
    };

    // Filter out the thematic with id 10010
    const filteredThematics = selectedThematics.filter(thematic => thematic.id !== 10010);

    return (
        <div style={{ display: 'flex', justifyContent: centered ? 'center' : 'flex-start', width: '100%' }}>
            <Breadcrumbs
                aria-label="breadcrumb"
                sx={{
                    mb: 2,
                    p: 2,
                    fontWeight: "bold",
                    fontSize: isMobile ? '1rem' : '0.875rem',
                    backgroundColor: isMobile ? '#f0f0f0' : 'inherit',
                    borderRadius: isMobile ? '4px' : '0',
                    padding: isMobile ? '8px' : '2px',
                    textAlign:  'center',
                }}
            >
                <Link
                    color="inherit"
                    onClick={() => handleBreadcrumbClick(0)}
                    sx={{ cursor: 'pointer', fontSize: isMobile ? '1rem' : '0.875rem', fontWeight: isMobile ? 'bold' : 'inherit' }}
                >
                    {selectedCarrier?.title}
                </Link>
                {filteredThematics?.map((thematic, index) => {
                    const isLast = index === filteredThematics.length - 1;
                    const isClickable = !isLast || !isLastThematicWithoutChildren(thematic.id);
                    return (
                        <Link
                            key={index}
                            color="inherit"
                            onClick={isClickable ? () => handleBreadcrumbClick(index + 1) : null}
                            sx={{
                                cursor: isClickable ? 'pointer' : 'default',
                                fontSize: isMobile ? '1rem' : '0.875rem',
                                fontWeight: isMobile ? 'bold' : 'inherit',
                                pointerEvents: isClickable ? 'auto' : 'none'
                            }}
                        >
                            {thematic.title}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
};

export default BreadcrumbsList;
