import React from 'react';
import { Box, Grid, Typography, IconButton, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';
import NoteAddSharpIcon from '@mui/icons-material/NoteAddSharp';
import ViewListSharpIcon from '@mui/icons-material/ViewListSharp';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

const options = [
    { title: 'Το Προφίλ μου', icon: <AccountCircleSharpIcon />, path: '/myprofile' },
    { title: 'Ανακοινώσεις', icon: <AnnouncementSharpIcon />, path: '/announcements' },
    { title: 'Νέο Αίτημα', icon: <NoteAddSharpIcon />, path: '/ticketform' },
    { title: 'Τα αιτήματα μου', icon: <ViewListSharpIcon />, path: '/mytickets' },
    { title: 'Θεματολογία Εξυπηρέτησης', icon: <HelpCenterIcon />, path: '/main' }
];

function SignedInMain() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: 'calc(50vh - 120px)',
                padding: 4,
                width: '100%',
                margin: '0 auto',
            }}
        >
            <Grid container spacing={3} justifyContent="center" alignItems="center">
                {options.map((option, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 200,
                                cursor: 'pointer',
                                padding: 2,
                                '&:hover': {
                                    boxShadow: 4,
                                },
                            }}
                            onClick={() => navigate(option.path)}
                        >
                            <IconButton size="large" sx={{ fontSize: 60, color: "#003476" }}>
                                {React.cloneElement(option.icon, { fontSize: "inherit" })}
                            </IconButton>
                            <Typography variant="h6" align="center">{option.title}</Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default SignedInMain;
