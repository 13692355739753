import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
    Container,
    CircularProgress,
    FormControl,
    useMediaQuery,
} from '@mui/material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {fetchSpecificUser, selectFetchedUser, updateUserThunk} from 'app/store/userSlice';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { validatePhoneNumber } from "app/main/SignedIn/TicketForm/utils";
import { jwtService } from "app/auth/jwtService";
import { showMessage } from "app/store/messageSlice";
import theme from "themes/theme";

const MyProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fetchedUserData = useSelector(selectFetchedUser);
    const fetchedUser = fetchedUserData ? fetchedUserData.data : null;
    const [localUser, setLocalUser] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [errors, setErrors] = useState({});
    const user = jwtService.getUser();
    const [isLoading, setLoading] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const fetchUser = async () => {
            setLoading(true);
            const response = await dispatch(fetchSpecificUser());
            if (response.payload) {
                const userData = response.payload.data;

                const formattedUser = {
                    ...userData,
                    phone: userData?.phone || '',
                    mobile: userData?.mobile || '',
                };

                setLocalUser(formattedUser);
            }
            setLoading(false);
        };

        if (!fetchedUser) {
            fetchUser();
        } else {
            const formattedUser = {
                ...fetchedUser,
                phone: fetchedUser?.phone || '',
                mobile: fetchedUser?.mobile || '',
            };

            setLocalUser(formattedUser);
        }
    }, []);

    const validateFields = (name, value) => {
        let error = '';
        if (name === 'email') {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailPattern.test(value)) {
                error = 'Παρακαλώ εισάγετε ένα έγκυρο email';
            }
        } else if (name === 'phone') {
            error = value ? validatePhoneNumber(value, 'landline') : '';
        } else if (name === 'mobile') {
            error = validatePhoneNumber(value, 'mobile');
        } else if (name === 'amka') {
            if (!value || value.length !== 11) {
                error = 'Παρακαλώ εισάγετε ένα έγκυρο ΑΜΚΑ (11 ψηφία)';
            }
        }
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'amka' || name === 'phone' || name === 'mobile') {
            if (/^\d*$/.test(value)) {
                setLocalUser((prev) => {
                    const updatedUser = { ...prev, [name]: value };
                    setIsChanged(JSON.stringify(updatedUser) !== JSON.stringify(fetchedUser));
                    return updatedUser;
                });
            }
        } else {
            setLocalUser((prev) => {
                const updatedUser = { ...prev, [name]: value };
                setIsChanged(JSON.stringify(updatedUser) !== JSON.stringify(fetchedUser));
                return updatedUser;
            });
        }
        validateFields(name, value);
    };

    const handleSave = async () => {
        if (Object.keys(errors).some((key) => errors[key])) {
            return;
        }

        const userToUpdate = { ...localUser };
        setLoading(true);
        try {
            const response = await dispatch(updateUserThunk(userToUpdate)).unwrap();

            if (response.status === 200) {
                // Notify success
                dispatch(showMessage({
                    message: 'Η ενημέρωση των στοιχείων ήταν επιτυχής',
                    autoHideDuration: 12000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "success",
                }));

                // Refetch user data after update
                await dispatch(fetchSpecificUser());
                setIsChanged(false);
            } else {
                throw new Error('Update failed');
            }
        } catch (error) {
            // Notify error
            dispatch(showMessage({
                message: 'Η ενημέρωση των στοιχείων απέτυχε',
                autoHideDuration: 12000,
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                },
                variant: "error",
            }));
        } finally {
            setLoading(false);
        }
    };


    return (
        <Container sx={{ marginTop: '50px', mb: 4 }}>
            <Button
                variant="contained"
                sx={{
                    backgroundColor: '#003476',
                    color: '#ffffff',
                    mt: 4,
                    mb: isMobile ? 4 : 0,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 3,
                    },
                }}
                startIcon={<ArrowCircleLeftIcon />}
                onClick={() => navigate("/mypage")}
                disabled={isLoading}
            >
                Πισω
            </Button>
            <Typography sx={{ color: "#003476" }} variant="h4" gutterBottom align="center">ΤΟ ΠΡΟΦΙΛ ΜΟΥ</Typography>
            <Box sx={{ backgroundColor: '#f5f5f5', padding: 4, borderRadius: 2 }}>
                <Typography variant="h6" gutterBottom align="left"
                            sx={{ display: 'flex', alignItems: 'center', color: '#003476' }}>
                    <AccountCircleIcon sx={{ marginRight: 1 }} />
                    Βασικές πληροφορίες
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="ΟΝΟΜΑ"
                            value={ user?.firstName || ''}
                            name="firstName"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            sx={{ input: { cursor: 'not-allowed' } }}
                            variant="outlined"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="ΕΠΙΘΕΤΟ"
                            value={ user?.lastName || ''}
                            name="lastName"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            sx={{ input: { cursor: 'not-allowed' } }}
                            variant="outlined"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Α.Φ.Μ"
                            value={ user?.afm || ''}
                            name="afm"
                            fullWidth
                            InputProps={{ readOnly: true }}
                            sx={{ input: { cursor: 'not-allowed' } }}
                            variant="outlined"
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="(*) ΑΜΚΑ"
                            value={localUser?.amka || ''}
                            name="amka"
                            fullWidth
                            variant="outlined"
                            onChange={handleChange}
                            error={!!errors.amka}
                            helperText={errors.amka}
                            disabled={isLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="(*) EMAIL"
                            variant="outlined"
                            value={localUser?.email || ''}
                            name="email"
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            disabled={isLoading}
                        />
                        <Box mt={2}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    fullWidth
                                    label="ΤΗΛΕΦΩΝΟ"
                                    variant="outlined"
                                    value={localUser?.phone || ''}
                                    name="phone"
                                    onChange={handleChange}
                                    error={!!errors.phone}
                                    helperText={errors.phone}
                                    disabled={isLoading}
                                />
                            </FormControl>
                        </Box>
                        <Box mt={2}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    fullWidth
                                    label="ΚΙΝΗΤΟ"
                                    variant="outlined"
                                    value={localUser?.mobile || ''}
                                    name="mobile"
                                    onChange={handleChange}
                                    error={!!errors.mobile}
                                    helperText={errors.mobile}
                                    disabled={isLoading}
                                />
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 2 }}>
                    {isLoading && <CircularProgress size={24} sx={{ marginRight: 2 }} />}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        disabled={!isChanged || isLoading || Object.keys(errors).some((key) => errors[key])}
                    >
                        ΑΠΟΘΗΚΕΥΣΗ
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default MyProfile;
