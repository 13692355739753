import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';

// Lazy load the FooterWrapper component
const FooterWrapper = lazy(() => import('./FooterWrapper'));

const LazyFooter = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const footerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Stop observing once it becomes visible
                }
            });
        });

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, []);

    return (
        <div>
            {children}
            <div ref={footerRef} style={{ minHeight: '100px' }}>
                {isVisible && (
                    <Suspense fallback={<></>}>
                        <FooterWrapper />
                    </Suspense>
                )}
            </div>
        </div>
    );
};

export default LazyFooter;
