import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import routesConfig from './app/configs/routesConfig';
import { AuthProvider, useAuth } from './app/auth/AuthContext';
import Message from './app/Message/MessageDialog';
import HeaderWrapper from 'Menu/HeaderWrapper';
import FooterWrapper from 'Menu/FooterWrapper';
import ScrollToTop from "app/main/SignedIn/Reusable Components/ScrollToTop";
import LazyFooter from "Menu/LazyFooter";

const App = () => {
    const routing = useRoutes(
        routesConfig.map((route) => {
            let RouteElement = route.element;

            if (route.inDrawer) {
                RouteElement = <HeaderWrapper>{RouteElement}</HeaderWrapper>;
            }

            const showFooter = route.showFooter ? (
                <LazyFooter>{RouteElement}</LazyFooter>
            ) : (
                RouteElement
            );

            return {
                path: route.path,
                element: <ProtectedRoute route={route} RouteComponent={showFooter} />,
            };
        })
    );

    return (
        <AuthProvider>
            <ScrollToTop />
            <Message />
            {routing}
        </AuthProvider>
    );
};

const ProtectedRoute = ({ route, RouteComponent }) => {
    const { isAuthenticated } = useAuth();
    return route.protected && !isAuthenticated ? (
        <Navigate to="/sign-in" />
    ) : (
        RouteComponent
    );
};

export default App;
