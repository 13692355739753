import {axiosApi, axiosNoInterceptors} from 'app/configs/axiosConfig';

export async function getQnaSearchResults(page, size, qnaSearchRequestBody) {
    try {
        const response = await axiosNoInterceptors.post(`/public/qna/search/${page}/${size}`, qnaSearchRequestBody);
        return response.data;
    } catch (error) {
        console.error('Error fetching QnA search results:', error);
        return null;
    }
}

// Function to get thematic data by ID
export async function getThematicById(id) {
    try {
        const response = await axiosApi.get(`/webportal/domain/thematic/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching thematic by ID:', error);
        return null;
    }
}

// Function to get thematic data by parent ID
export async function getThematicByParentId(id) {
    try {
        const response = await axiosNoInterceptors.get(`/public/thematic/byParentId/active/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching thematic by parent ID:', error);
        return null;
    }
}

// Function to get thematic data by carrier ID
export async function getThematicByCarrierId(id) {
    try {
        const response = await axiosNoInterceptors.get(`/public/thematic/byCarrierId/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching thematic by carrier ID:', error);
        return null;
    }
}

// Function to get all carriers
export async function getAllCarriers() {
    try {
        const response = await axiosNoInterceptors.get('/public/carrier/all');
        return response.data;
    } catch (error) {
        console.error('Error fetching all carriers:', error);
        return null;
    }
}

// Function to get geographies by carrier ID
export async function getGeographiesByCarrierId(id) {
    try {
        const response = await axiosApi.get(`/webportal/domain/geographyByCarrierId/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching geographies by carrier ID:', error);
        return null;
    }
}

