import {axiosNoInterceptors} from "app/configs/axiosConfig";


const TaxisPostCode = async (code) => {
    try {
        const response = await axiosNoInterceptors.post('/public/webportal/login', {
            code: code
        });

        if (response.status !== 200) {
            throw new Error('Taxis not 200 error');
        }

        return response.data;
    } catch (error) {
        console.error('There was a problem with the taxis request:', error.message);

        throw new Error('Taxis request failed');  
    }
}

export default TaxisPostCode;
