import React, {useEffect, useRef, useState} from 'react';
import {Box, Typography, Paper, IconButton} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CloseIcon from '@mui/icons-material/Close';
import {FileLogo} from "app/main/SignedIn/Reusable Components/FileLogo";

const TicketFileSelect = ({files, setFiles, selectedCarrierId, useOriginFileName = false, fileError, setFileError}) => {
    const fileInputRef = useRef(null);
    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files).map((file) => ({
            file: file,
            name: file.name,
            type: file.type || file.name.split('.').pop(),
            isNew: true,
        }));

        // Check for invalid file types
        const invalidFiles = selectedFiles.filter(
            (file) =>
                !['application/pdf', 'image/jpeg', 'image/jpg'].includes(
                    file.file.type
                )
        );
        if (invalidFiles.length > 0) {
            setFileError('Επιτρέπονται μόνο pdf, jpeg, jpg αρχεία');
            return;
        }

        // Remove duplicate files
        const uniqueFiles = selectedFiles.filter(
            (newFile) =>
                !files.some((existingFile) => existingFile.name === newFile.name)
        );

        // Check for maximum file limit
        if (files.length + uniqueFiles.length > 5) {
            setFileError('Μπορείτε να ανεβάσετε μέχρι 5 αρχεία');
            return;
        }

        if (selectedCarrierId === 12) {
            const pdfFiles = uniqueFiles.filter(
                (file) => file.file.type === 'application/pdf'
            );
            if (pdfFiles.length < 1) {
                setFileError(
                    'Θα πρέπει να ανεβάσετε Υπεύθυνη Δήλωση υπογεγραμμένη μέσω της ενιαίας ψηφιακής πύλης της Δημόσιας Διοίκησης (<a href="https://www.gov.gr/" target="_blank" rel="noopener noreferrer">www.gov.gr</a>)'
                );
                setFiles([]);
                return;
            } else {
                setFileError('');
                setFiles(pdfFiles);
            }
        } else {
            setFileError('');
            setFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
        }

        // Reset input value to allow re-selecting the same file
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };



    useEffect(() => {
        if (selectedCarrierId === 12) {
            const pdfFiles = files.filter(file => file.file.type === 'application/pdf');
            if (pdfFiles.length < 1) {
                setFileError('Θα πρέπει να ανεβάσετε Υπεύθυνη Δήλωση υπογεγραμμένη μέσω της ενιαίας ψηφιακής πύλης της Δημόσιας Διοίκησης (<a href="https://www.gov.gr/" target="_blank" rel="noopener noreferrer">www.gov.gr</a>)');
                setFiles([]);
            } else {
                setFileError('');
                setFiles([pdfFiles[0]]);
            }
        } else {
            setFileError('');
        }
    }, [selectedCarrierId]);

    const handleRemoveFile = (index) => {
        if (!useOriginFileName) {
            setFiles((prev) => {
                const updatedFiles = prev.filter((_, i) => i !== index);
                if (selectedCarrierId === 12 && updatedFiles.length === 0) {
                    setFileError('Θα πρέπει να ανεβάσετε Υπεύθυνη Δήλωση υπογεγραμμένη μέσω της ενιαίας ψηφιακής πύλης της Δημόσιας Διοίκησης (<a href="https://www.gov.gr/" target="_blank" rel="noopener noreferrer">www.gov.gr</a>)');
                } else {
                    setFileError('');
                }
                return updatedFiles;
            });
        }
    };


    const fileInputProps = {
        accept: ".pdf, .jpeg, .jpg",
        multiple: selectedCarrierId !== 12,
        onChange: handleFileChange,
        style: {display: 'none'},
        id: 'file-input',
        ref: fileInputRef,
    };

    return (
        <Box sx={{marginTop: 1, marginBottom: 1}}>
            <input type="file" {...fileInputProps} />
            <label htmlFor="file-input">
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100px',
                    border: fileError ? '1px solid red' : '1px dashed grey',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    gap: 2
                }}>
                    <UploadFileIcon color="action"/>
                    <Typography variant="body1">Ανέβασμα αρχείου</Typography>
                </Box>
            </label>
            <Box sx={{display: 'flex', flexDirection: 'column', mt: 1}}>
                <Typography variant="caption" color="textSecondary">
                    {selectedCarrierId === 12
                        ? '* Η Υπεύθυνη δήλωση θα πρέπει να είναι σε pdf μορφή'
                        : '* Επιτρέπονται pdf, jpeg, jpg αρχεία'
                    }
                </Typography>
                {fileError && (
                    <Typography variant="caption" color="error" sx={{mt: 1}}
                                dangerouslySetInnerHTML={{__html: fileError}}
                    />
                )}
            </Box>
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2}}>
                {files.map((file, index) => (
                    <Paper key={index} sx={{
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        width: 'fit-content',
                        backgroundColor: "#e6e4e4"
                    }}>
                        <FileLogo extension={useOriginFileName ? file.extension : file.type}/>
                        <Typography variant="body2"
                                    sx={{mr: 1}}>{useOriginFileName ? file.originFileName : file.name}</Typography>
                        {!useOriginFileName && (
                            <IconButton onClick={() => handleRemoveFile(index)} size="small">
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        )}
                    </Paper>
                ))}
            </Box>
        </Box>
    );
};

export default TicketFileSelect;
