import Error404Page from "app/401/Error404Page";
import Authorization from "app/auth/Authorization";
import SignInPage from "app/Sign-In/SignInPage";
import {Navigate} from "react-router-dom";
import Taxis from "app/Sign-In/Taxis";
import Tree from "app/main/KnowledgeBase/Tree";
import CarrierThematicsPage from "app/main/KnowledgeBase/CarrierThematicsPage";
import SignedInMain from "app/main/SignedIn/SignedInMain";
import Odigies from "app/main/KnowledgeBase/Odigies";
import MyTicketsPage from "app/main/SignedIn/MyTickets/MyTicketsPage";
import TicketForm from "app/main/SignedIn/TicketForm/TicketForm";
import MyProfile from "app/main/SignedIn/MyProfile";
import AnnouncmentsPage from "app/main/SignedIn/Announcments/AnnouncmentsPage";
import SignOutPage from "Menu/SignOutPage";

const routesConfig = [
    {
        path: '/',
        element: <Navigate to="/main" replace/>,
        inDrawer: true,
        protected: false,
        showFooter: true,
    },
    {
        path: '/main',
        element: <Tree/>,
        inDrawer: true,
        protected: false,
        showFooter: true,
    },
    {
        path: '/sign-out',
        element: <SignOutPage />,
        protected: true,
        inDrawer: false,
        showFooter: false,
    },
    {
        path: '/thematicqnas',
        element: <CarrierThematicsPage/>,
        inDrawer: true,
        protected: false,
        showFooter: true,
    },
    {
        path: '/odigies-xrisis',
        element: <Odigies/>,
        inDrawer: true,
        protected: false,
        showFooter: true,
    },
    {
        path: '/wp-json/webticket1555/v1/aauth/',
        element: (
            <Taxis/>
        ),
        protected: false,
        inDrawer: false,
        showFooter: false,
    },
    {
        path: '/mypage',
        element: (
            <Authorization>
                <SignedInMain/>
            </Authorization>
        ),
        protected: true,
        inDrawer: true,
        showFooter: true,
    },
    {
        path: '/mytickets',
        element: (
            <Authorization>
                <MyTicketsPage/>
            </Authorization>
        ),
        protected: true,
        inDrawer: true,
        showFooter: true,
    },
    {
        path: '/ticketform',
        element: (
            <Authorization>
            <TicketForm/>
            </Authorization>
        ),
        protected: true,
        inDrawer: true,
        showFooter: true,
    },
    {
        path: '/myprofile',
        element: (
            <Authorization>
                <MyProfile/>
            </Authorization>
        ),
        protected: true,
        inDrawer: true,
        showFooter: true,
    },
    {
        path: '/announcements',
        element: (
                <AnnouncmentsPage/>
        ),
        protected: false,
        inDrawer: true,
        showFooter: true,
    },
    {
        path: '/sign-in',

    element:
        <Authorization>
            <SignInPage/>
        </Authorization>,
        inDrawer: true,
        protected: false,
        showFooter: false,
    },
    {
        path: '*',
        element: <Error404Page/>,
        inDrawer: true,
        protected: false,
        showFooter: false,
    },
];

export default routesConfig;
