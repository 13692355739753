import React from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { CircularProgress, InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";

const CustomSelect = ({ label, value, onChange, onClear, options = [], loading, disabled }) => (
    <FormControl fullWidth margin="normal">
        <InputLabel shrink={true}>{label}</InputLabel>
        <Select
            value={value || ''}
            onChange={onChange}
            endAdornment={
                <InputAdornment position="start">
                    <IconButton
                        onClick={onClear}
                        edge="start"
                        size="small"
                        disabled={disabled}
                        sx={{ marginLeft: '-12px' }}
                    >
                        <ClearIcon />
                    </IconButton>
                    {loading && <CircularProgress size={24} />}
                </InputAdornment>
            }
            label={label}
            MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 300,
                    },
                },
            }}
            sx={{
                backgroundColor: "#f0f0f0",
                height: '40px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                borderRadius: '4px',
                '& fieldset': {
                    border: 'none',
                },
                '&:hover fieldset': {
                    border: 'none',
                },
                '&.Mui-focused fieldset': {
                    border: 'none',
                },
            }}
        >
            {Array.isArray(options) && options.length > 0 ? (
                options.map((option) => (
                    <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={{ whiteSpace: 'normal', maxWidth: '100%', wordBreak: 'break-word' }}>
                        {option.title}
                    </MenuItem>
                ))
            ) : (
                <MenuItem disabled>
                    <em>Δεν υπάρχουν επιλογές</em>
                </MenuItem>
            )}
        </Select>
    </FormControl>
);

export default CustomSelect;
