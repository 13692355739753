import React, { useState, useEffect } from 'react';
import { Typography, Modal, Box, Button, Paper } from '@mui/material';
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { getAnnouncementFileDownload, getAnnouncementFiles } from "app/Api Calls/AnnouncementsCalls";
import { FileLogo } from "app/main/SignedIn/Reusable Components/FileLogo";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "themes/theme";

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

function determineDateColor(validUntil) {
    if (!validUntil) return 'gray';
    const now = new Date();
    const validDate = new Date(validUntil);
    return validDate >= now ? 'green' : 'red';
}

function AnnouncementModal({ open, handleClose, announcement }) {
    const [files, setFiles] = useState([]);
    const [displayAnnouncement, setDisplayAnnouncement] = useState(null);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        if (open && announcement) {
            setDisplayAnnouncement(announcement);
            getAnnouncementFiles(announcement.id)
                .then(setFiles)
                .catch(error => console.error('Error fetching announcement files:', error));
        } else {
            setDisplayAnnouncement(null);
            setFiles([]);
        }
    }, [open, announcement]);

    const handleFileClick = async (file) => {
        try {
            const fileUrl = await getAnnouncementFileDownload(file.id);
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            // Ensure the file name has the correct extension
            const filename = `${file?.originFileName}.${file?.extension}`;

            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // Release the blob URL
            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <Modal
            aria-labelledby="announcement-modal"
            aria-describedby="announcement-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <Box sx={{
                bgcolor: '#EDF0F6',
                width: isMobile ? '90vw' : "75vw",
                height: isMobile ? 'auto' : '90vh',
                maxHeight: '90vh',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
                borderRadius: "5px"
            }}>
                <Box sx={{ p: 4, overflowY: 'auto', flexGrow: 1 }}>
                    {displayAnnouncement && (
                        <>
                            <Typography variant="h5" gutterBottom>{displayAnnouncement.title}</Typography>
                            <Box dangerouslySetInnerHTML={{ __html: displayAnnouncement.bodyText }} />
                            <Typography variant="body1" color="textSecondary" gutterBottom>
                                Δημοσιεύθηκε: {formatDate(displayAnnouncement.createdAt)}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <EventAvailableIcon sx={{
                                    fontSize: 20,
                                    color: determineDateColor(announcement?.validUntil)
                                }} />
                                {announcement?.validUntil ? (
                                    <Typography variant="body2" component="div" sx={{
                                        ml: 0.5,
                                        color: determineDateColor(announcement?.validUntil)
                                    }}>
                                        Ενεργό έως: {formatDate(announcement?.validUntil)}
                                    </Typography>
                                ) : (
                                    <Typography variant="body2" component="div" sx={{
                                        ml: 0.5,
                                        color: 'gray'
                                    }}>
                                        Δεν υπάρχει ημερομηνία
                                    </Typography>
                                )}
                            </Box>

                            {/* Display files */}
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                                {files.map((file, index) => (
                                    <Paper key={index} sx={{
                                        p: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                        width: 'fit-content',
                                        cursor: 'pointer'
                                    }}
                                           onClick={() => handleFileClick(file)}>
                                        <FileLogo extension={file.extension} />
                                        <Typography variant="body2" sx={{ mr: 1 }}>{file.originFileName}</Typography>
                                    </Paper>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
                {/* Close button */}
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2,
                    bgcolor: '#EDF0F6',
                    borderTop: '1px solid #ccc',
                }}>
                    <Button onClick={handleClose}
                            sx={{
                                bgcolor: '#003476 !important',
                                color: '#fff',
                                '&:hover': {
                                    bgcolor: '#002350 !important'
                                }
                            }}>Κλείσιμο</Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default AnnouncementModal;
