import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#edf0f6',
    },
    primary: {
      main: '#003476',
      fontSize: 13,
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      below1300: 1300,
    },
  },
});

export default theme;
